import View from './view'
import { connect } from 'react-redux'
import { getTableFiltersURLQueryParam } from '@shared/utils'
import { EMPTY_ARRAY } from '@root/constants'

const mapStateToProps = (state, ownProps) => {
  const { supportViews, filters, isScrolling } = ownProps
  const URLFilters = getTableFiltersURLQueryParam()
  const supportURLFilters = URLFilters.length && !supportViews
  const currentFilters = supportURLFilters ? URLFilters : filters || EMPTY_ARRAY

  return {
    filters: currentFilters,
    isScrolling,
    supportURLFilters
  }
}

export default connect(mapStateToProps)(View)
