import { getWorkflowLastStatus } from '.'
import moment from 'moment'
import { LastExecution, Workflow } from './types'

interface Row {
  originalRow: Workflow
  latestExecution: LastExecution | null
}

export const sortLastStatus = (
  a: Row,
  b: Row
): number => {
  const {
    originalRow: workflowA,
    latestExecution: latestExecutionA
  } = a

  const {
    originalRow: workflowB,
    latestExecution: latestExecutionB
  } = b

  const isWorkflowAHasStatus = Boolean(workflowA.isThrottled || latestExecutionA?.status)
  const isWorkflowBHasStatus = Boolean(workflowB.isThrottled || latestExecutionB?.status)

  if (!isWorkflowAHasStatus && !isWorkflowBHasStatus) {
    return 0
  }
  if (!isWorkflowAHasStatus) {
    return 1
  }
  if (!isWorkflowBHasStatus) {
    return -1
  }

  const aWorkflowStatus = getWorkflowLastStatus(workflowA) ?? ''
  const bWorkflowStatus = getWorkflowLastStatus(workflowB) ?? ''

  const compareStatus = aWorkflowStatus.localeCompare(bWorkflowStatus)

  if (compareStatus !== 0) {
    return compareStatus
  }

  const timeA = moment.utc(workflowA.throttlingStartTime || latestExecutionA?.completionTime || latestExecutionA?.creationTime || 0).valueOf()
  const timeB = moment.utc(workflowB.throttlingStartTime || latestExecutionB?.completionTime || latestExecutionB?.creationTime || 0).valueOf()

  return timeB - timeA
}
