import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import DetailsGroup from '../detailsGroup'
import Analytics from '@helpers/analytics'
import texts from '@shared/style/texts'
import InputPopup from '../popups/inputPopup'
import { Button, ButtonType, Spacer, H4 } from '@toriihq/design-system'
import { formFieldTypes, formFieldTypeToName, SCOPES, EMPTY_OBJECT } from '@root/constants'
import OptionsPopup from '../popups/optionsPopup'
import pluralize from 'pluralize'
import EnableFor from '../enableFor'
import VisibleFor from '../visibleFor'
import InaccessiblePage from '@root/pages/inaccessiblePage/inaccessiblePage'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

const CSS = {
  subHeader: css(texts.heading, {
    textTransform: 'uppercase',
    marginBottom: '19px'
  }),
  footer: css({
    textAlign: 'center',
    marginBottom: '48px'
  })
}

class DetailsConfiguration extends React.Component {
  state = {
    isCreateOpen: false,
    groupIdsToCollapsed: {}
  }

  componentDidMount () {
    if (this.props.idOrg) {
      this.props.fetchData()
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.idOrg !== this.props.idOrg) {
      this.props.fetchData()
    }
  }

  toggleCreateGroupState = (isClosedByUser = true) => {
    const { itemName } = this.props
    const isCreateOpen = !this.state.isCreateOpen

    if (isCreateOpen) {
      Analytics.track(`Clicked to add ${itemName} fields group`)
    } else if (isClosedByUser) {
      Analytics.track(`Closed add ${itemName} fields group`)
    }

    this.setState({ isCreateOpen })
  }

  addGroup = async ({ fieldName }) => {
    const { addDetailsGroup } = this.props
    return addDetailsGroup({ label: fieldName })
  }

  onDragEnd = result => {
    const { destination, source } = result
    if (!destination) {
      return
    }

    const sourceIndex = source.index
    const destinationIndex = destination.index

    if (destinationIndex === sourceIndex) {
      return
    }

    const { groups, reorderDetailsGroups } = this.props
    const reorderedGroups = groups.slice()
    const sourceGroup = reorderedGroups[sourceIndex]
    reorderedGroups.splice(sourceIndex, 1)
    reorderedGroups.splice(destinationIndex, 0, sourceGroup)

    const changes = []
    const startIndex = Math.min(sourceIndex, destinationIndex)
    const endIndex = Math.max(sourceIndex, destinationIndex)
    const groupsPositions = groups.map(group => group.position)
    for (let i = startIndex; i <= endIndex; i++) {
      reorderedGroups[i].position = groupsPositions[i]
      changes.push({
        idGroup: reorderedGroups[i].id,
        position: reorderedGroups[i].position
      })
    }
    return reorderDetailsGroups({
      sourceGroup: groups[sourceIndex],
      destinationGroup: groups[destinationIndex],
      changes,
      reorderedGroups
    })
  }

  onDetailsGroupHeaderClick = (groupId) => {
    const newGroupIdsToCollapsed = { ...this.state.groupIdsToCollapsed }
    newGroupIdsToCollapsed[groupId] = !this.isGroupCollapsed(groupId)
    this.setState({
      groupIdsToCollapsed: newGroupIdsToCollapsed
    })
  }

  isGroupCollapsed = (groupId) => this.state.groupIdsToCollapsed[groupId] ?? false

  customColumns = [
    {
      Header: 'In use by',
      accessor: 'usedByCount',
      Cell: ({ value: usedByCount, original: { hasSharedValue } }) => {
        if (hasSharedValue) {
          return 'All apps'
        }

        return (pluralize(this.props.itemName, usedByCount, true))
      }
    }
  ]

  render () {
    const { groups, fieldsByGroups, loading, idOrg, isEditFieldOpen, editableField, updateField, toggleUpdateDetailsField, deleteDetailsField, deleteDetailsGroup, editDetailsGroup, addDetailsField, updateDetailsField, groupsForSelectGroup, itemName, alwaysShownFields, colorableField, subHeader, allowedScopes, visibilityScopes, onRowsOrderChanged } = this.props
    const { isCreateOpen } = this.state
    const createGroupPopup = <InputPopup
      isOpen={isCreateOpen}
      header='New Group'
      cancelButton={{ label: 'Cancel', onClick: this.toggleCreateGroupState }}
      submitButton={{ label: 'Add', onClick: this.addGroup }}
      input={{ label: 'Group Name' }}
      showCheckBox={false}
    />

    const { idField, idGroup, type, name, formQuestion, options, isPredefined } = editableField
    const isDropdownField = editableField && [formFieldTypes.dropdown, formFieldTypes.dropdownMulti].includes(type)
    const editSimpleFieldPopup = <InputPopup
      isOpen={isEditFieldOpen}
      header={`Edit ${formFieldTypeToName[type]} field details`}
      cancelButton={{ label: 'Cancel', onClick: (isByUser) => toggleUpdateDetailsField({ isByUser, isOpen: false, name, idField }) }}
      submitButton={{ label: 'Save', onClick: ({ fieldName, idGroup, formQuestion }) => updateField({ name: fieldName, idGroup, formQuestion }) }}
      input={{ label: 'Field Name', fieldName: name, idGroup, formQuestion }}
      isPredefined={isPredefined}
      editMode
      showGroupDropdown
      groupsForSelectGroup={groupsForSelectGroup}
    />

    const editDropdownFieldPopup = <OptionsPopup
      isOpen={isEditFieldOpen}
      header={`Edit ${formFieldTypeToName[type]} field details`}
      cancelButton={{ label: 'Cancel', onClick: (isByUser) => toggleUpdateDetailsField({ isByUser, isOpen: false, name, idField }) }}
      submitButton={{ label: 'Save', onClick: ({ idGroup, fieldName, formQuestion, fieldOptions }) => updateField({ name: fieldName, idGroup, formQuestion, options: fieldOptions }) }}
      input={{ label: 'Field Name', fieldName: name, idGroup, formQuestion, idField }}
      options={options}
      isPredefined={isPredefined}
      editMode
      showGroupDropdown
      showCheckBox={!isPredefined}
      groupsForSelectGroup={groupsForSelectGroup}
      colorableField={colorableField}
    />

    const detailsGroups = () => <Droppable
      droppableId='droppable'
    >
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {groups.map((group, index) => <DetailsGroup
            key={group.id}
            index={index}
            idOrg={idOrg}
            loading={loading}
            group={group}
            fields={fieldsByGroups[group.id] || []}
            deleteDetailsField={deleteDetailsField}
            toggleUpdateDetailsField={toggleUpdateDetailsField}
            deleteDetailsGroup={deleteDetailsGroup}
            editDetailsGroup={editDetailsGroup}
            addDetailsField={addDetailsField}
            updateDetailsField={updateDetailsField}
            groupsForSelectGroup={groupsForSelectGroup}
            customColumns={this.customColumns}
            itemName={itemName}
            allowedScopes={allowedScopes}
            alwaysShownFields={alwaysShownFields}
            onHeaderClick={this.onDetailsGroupHeaderClick}
            onlyShowHeader={this.isGroupCollapsed(group.id)}
            isSelected={false}
            onRowsOrderChanged={onRowsOrderChanged}
          />)}
          {provided.placeholder}
        </div>
      )}
    </Droppable>

    return (
      <VisibleFor scopes={visibilityScopes} fallback={<InaccessiblePage />}>
        <div>
          {isCreateOpen && createGroupPopup}
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div
              style={{ height: '100vh', overflowY: 'auto' }}
            >
              <Spacer bottom={'space-300'}><H4>{subHeader}</H4></Spacer>
              {detailsGroups()}
              <div {...CSS.footer}><EnableFor scopes={[SCOPES.APPLICATIONS_WRITE]}><Button type={ButtonType.secondary} onClick={this.toggleCreateGroupState} label='Add new group' icon='Plus' /></EnableFor></div>
              {(isEditFieldOpen && (isDropdownField ? editDropdownFieldPopup : editSimpleFieldPopup))}
            </div>
          </DragDropContext>
        </div>
      </VisibleFor>
    )
  }
}

DetailsConfiguration.propTypes = {
  groups: PropTypes.array,
  fieldsByGroups: PropTypes.object,
  loading: PropTypes.bool,
  idOrg: PropTypes.number,
  isEditFieldOpen: PropTypes.bool,
  editableField: PropTypes.object,
  updateField: PropTypes.func,
  toggleUpdateDetailsField: PropTypes.func,
  deleteDetailsField: PropTypes.func,
  deleteDetailsGroup: PropTypes.func,
  editDetailsGroup: PropTypes.func,
  addDetailsField: PropTypes.func,
  updateDetailsField: PropTypes.func,
  groupsForSelectGroup: PropTypes.array,
  itemName: PropTypes.string,
  alwaysShownFields: PropTypes.array,
  colorableField: PropTypes.bool,
  subHeader: PropTypes.string,
  allowedScopes: PropTypes.array,
  visibilityScopes: PropTypes.array
}

DetailsConfiguration.defaultProps = {
  editableField: EMPTY_OBJECT,
  colorableField: false,
  subHeader: '',
  allowedScopes: [],
  visibilityScopes: []
}
export default DetailsConfiguration
