import React from 'react'
import { getValue } from '@shared/utils'
import Select from '@components/select'
import { partition, isEmpty } from 'lodash'
import { CSS } from './styles'
import { criteriaModelTypes } from '@components/filters/consts'
import {
  UserEntityCriterionKeys,
  UserKeyOption
} from '@components/filters/userVerticalFilter/types'
import {
  AppUserCriterion,
  UserCriterion
} from '@shared/filters/userFilters/types'
import KeyOpValueVerticalFilter from '@components/filters/keyOpValueVerticalFilter'
import { getUpdatedCriterion } from '@components/filters/userVerticalFilter/utils/getUpdatedCriterion'
import { SelectComponents } from '@toriihq/design-system'

interface Props {
  criterion: AppUserCriterion | UserCriterion
  optionsKey?: UserKeyOption[]
  optionsValuesPerKey?: object
  disabled: boolean
  onCriterionChange: (criterion: AppUserCriterion | UserCriterion) => void
}

const UserVerticalFilter = ({
  criterion,
  optionsKey = [],
  optionsValuesPerKey = {},
  disabled,
  onCriterionChange
}: Props) => {
  const onChange = ({ key, newValue }: { key: UserEntityCriterionKeys, newValue: any }): void => {
    const updatedCriterion = getUpdatedCriterion({ criterion, key, newValue })
    onCriterionChange(updatedCriterion)
  }

  const renderCriteriaType = ({ criteriaTypeOptions, type, account }) => {
    let [accountOptions, criteriaOptions] = partition(criteriaTypeOptions, option => option.modelType === criteriaModelTypes.account)

    let appAccountOptions = type && type.modelType === criteriaModelTypes.app ? accountOptions.filter(account => account.idApp === type.value) : null

    const isAccountNotInCriteriaOptions = account && !isEmpty(criteriaOptions) && !criteriaOptions.find(option => option.value === account.idApp)
    const isAccountNotInAppAccountOptions = account && !isEmpty(accountOptions) && !appAccountOptions?.find(option => option.value === account.value)

    let showError = false
    if (isAccountNotInAppAccountOptions) {
      const disabledAccount = { ...account, disabled: true }
      appAccountOptions = (appAccountOptions || []).concat([disabledAccount])
      showError = true
    }
    if (isAccountNotInCriteriaOptions) {
      const disabledType = { ...type, disabled: true }
      criteriaOptions = criteriaOptions.concat([disabledType])
      showError = true
    }

    const [appOptions, generalOptions] = partition(criteriaOptions, option => option.modelType === criteriaModelTypes.app)
    return (
      <>
        <Select
          options={generalOptions.concat({ type: SelectComponents.SelectOptionDivider }).concat(appOptions)}
          value={type}
          onChange={(newValue) => onChange({ key: 'type', newValue })}
          clearable={false}
          searchable
          openOnFocus
          disabled={disabled}
        />
        {appAccountOptions && <div {...CSS.divWrapSelectWithError}>
          <Select
            hasError={showError}
            options={appAccountOptions}
            value={account}
            onChange={(newValue) => onChange({ key: 'account', newValue })}
            clearable={false}
            searchable
            openOnFocus
            disabled={disabled}
          />
          {showError && <span {...CSS.spanError}>Integration with account was disconnected</span>}
        </div>}
      </>
    )
  }

  const getOptionsKeyByUserSelection = (optionsKey, account) => {
    const { value: idAppAccount } = account || {}
    return optionsKey.filter(option => option.idAppAccount === idAppAccount)
  }

  const [criteriaTypeOptions, filtersOptions] = partition(optionsKey, option => Object.values(criteriaModelTypes).includes(option.modelType))
  const { type, account, filters } = criterion
  const typeSelected = type && type.value
  const accountSelected = account && account.value
  const criterionTypeSelectionCompleted = typeSelected && (type.modelType === criteriaModelTypes.userFields || accountSelected)
  const selectionOptionsKey = criterionTypeSelectionCompleted ? getOptionsKeyByUserSelection(filtersOptions, account) : []
  const optionsValues = filters ? optionsValuesPerKey[getValue(filters.key)] : []
  const CriterionFilters = renderCriteriaType({ criteriaTypeOptions, type, account })
  const renderKeyOpValueFilter = Boolean(criterionTypeSelectionCompleted)
  return (
    <>
      {CriterionFilters}
      {renderKeyOpValueFilter && (
        <KeyOpValueVerticalFilter
          filter={filters}
          optionsKey={selectionOptionsKey}
          optionsValues={optionsValues}
          onChange={(filter) => onChange({ key: 'filters', newValue: filter })}
          disabled={disabled}
          fieldsAutoFocus={false}
          allowCustomValuesInMultiDropdown
        />
      )}
    </>
  )
}

export default UserVerticalFilter
