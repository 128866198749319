import Analytics from '@pages/app/overview/analytics'
import { WIZARD_STEPS } from '@pages/renewalForecast/wizardSteps/types'
import { Button, Tooltip } from '@toriihq/design-system'
import {
  toggleRenewalForecastNotSupportedPopup
} from '@actions/'
import { setRenewalForecastData } from '@root/store/actions'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { FEATURE_TO_PLAN, FEATURES } from '@shared/features'
import AccessControl from '@lenses/accessControl'
import { SCOPES, SCOPES_PREFIX } from '@root/constants'
import { RenewalForecastButtonFromLocation } from './types'
import { capitalize } from 'lodash'

export interface RenewalForecastButtonProps {
    idOrg: string
    idApp: string
    appAccounts: any
    appName: string
  buttonFromLocation: RenewalForecastButtonFromLocation
}

const RenewalForecastButton = ({ idOrg, idApp, appAccounts, appName, buttonFromLocation }:RenewalForecastButtonProps) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const renewalForecastFeature = FEATURES.LICENSES.COMPONENTS.LICENSE_AUDIT
  const isRenewalForecastFeatureEnabledInPlan = AccessControl.useIsFeatureEnabledInPlan({ feature: renewalForecastFeature })
  const hasScopesForRenewalForecast = AccessControl.useIsAccessible({ scopes: [SCOPES.CONTRACTS_READ, SCOPES.LICENSE_AND_CHARGEBACK_READ, SCOPES.USERS_READ], mustHaveAllScopes: true })
  const isAppOwner = AccessControl.useIsAccessible({ scopes: [`${SCOPES_PREFIX.APP_OWNER}-${idOrg}-app-${idApp}`] })
  const shouldDisableButton = (!hasScopesForRenewalForecast && !isAppOwner) || !isRenewalForecastFeatureEnabledInPlan

  function onRenewalForecastClick () {
    Analytics.onClickStartRenewalForecastButton({ appName, numOfAccounts: appAccounts.length, buttonFromLocation })
    if (appAccounts.length > 1) {
      dispatch(toggleRenewalForecastNotSupportedPopup({ isOpen: true, appName }))
    } else if (appAccounts.length === 0) {
      dispatch(setRenewalForecastData({ idApp, idAppAccount: null }))
      history.push(`/team/${idOrg}/app/${idApp}/account/0/renewalForecast/${WIZARD_STEPS.USER_REVIEW}`)
    } else {
      dispatch(setRenewalForecastData({ idApp, idAppAccount: appAccounts[0].idAppAccount }))
      history.push(`/team/${idOrg}/app/${idApp}/account/${appAccounts[0].idAppAccount}/renewalForecast/${WIZARD_STEPS.USER_REVIEW}`)
    }
  }

  const requiredPlan = FEATURE_TO_PLAN[renewalForecastFeature]
  const featureDisableTooltip = `${capitalize(requiredPlan)} plan feature`
  const noPermissionsDisableTooltip = 'Renewal forecast isn’t available because you’re missing access permissions for contracts and/or user attributes.'
  const disabledTooltipText = isRenewalForecastFeatureEnabledInPlan ? noPermissionsDisableTooltip : featureDisableTooltip
  const icon = isRenewalForecastFeatureEnabledInPlan ? undefined : 'Lock'
  return (<Tooltip label={disabledTooltipText} hide={!shouldDisableButton} >
    <Button label={'Start renewal forecast'}
      onClick={onRenewalForecastClick}
      disabled={shouldDisableButton}
      icon={icon} /></Tooltip>)
}
export default RenewalForecastButton
