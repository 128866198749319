import { EmptyState } from '@toriihq/design-system'
import NoResults from '@media/no-results.svg'
import { EmptyStateContainer } from './style'

const WidgetEmptyState = () => {
  return <EmptyStateContainer>
    <EmptyState
      image={<img src={NoResults} alt='No results found' />}
      description='No results found for the selected filters'
    />
  </EmptyStateContainer>
}

export default WidgetEmptyState
