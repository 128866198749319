import { Widget } from '@reducers/dashboards/types'
import { IdDashboard } from '@store/types'

export interface WidgetProps {
  idDashboard: IdDashboard,
  widget: Widget,
  isSelected: boolean,
  onViewDataButtonClick: (widget: Widget) => void
}

export enum DROPDOWN_OPTIONS_TYPE {
  GROUP_BY = 'GROUP_BY',
  NUMERIC = 'NUMERIC'
}
interface EditableField {
  key: string
  label: string
  type: string
  options?: { label: string; value: string }[]
  dropdownOptionsType?: DROPDOWN_OPTIONS_TYPE
  showOnFieldSpecificCondition?: {
    fieldKey: string
    comparisonType: 'value' | 'type'
    value: string | string[]
    op: string
  }
  disabled?: boolean
}

export interface EditableSection {
  key: string
  label?: string
  fields: EditableField[]
}

export interface WidgetConfig {
  editableSections: EditableSection[]
}
