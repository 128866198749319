import View from './view'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getCurrentOrg } from '@selectors/org'
import { getRecommendations } from '@selectors/recommendations'
import { getRecommendations as getAllRecommendations } from '@actions/'
import AccessControl from '@lenses/accessControl'

const mapStateToProps = (state, ownProps) => {
  const org = getCurrentOrg(state)
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const { recommendations } = getRecommendations(state)
  const isTabEnabled = (scopes, mustHaveAllScopes) => AccessControl.isAccessibleWithState({ scopes, state, mustHaveAllScopes })

  return {
    org,
    idOrg,
    isTabEnabled,
    totalRecommendations: recommendations.length
  }
}

const mapDispatchToProps = {
  getAllRecommendations
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
