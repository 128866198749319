import styled from 'styled-components'

export const NavigationMenuLink = styled.div<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  height: 40px;
  min-width: max-content;
  padding: 0 12px;
  margin: 0 16px;
  border-radius: 40px;
  font: ${({ theme }) => theme.typography.font.subtitle02};
  font-weight: semibold;

  color: ${({ theme, $isSelected }) => $isSelected ? theme.palette.link.primary : theme.palette.text.primary};
  background-color: ${({ theme, $isSelected }) => $isSelected ? theme.palette.background.secondaryActive : 'inherit'};

  &:hover {
    color: ${({ theme, $isSelected }) => theme.palette.link.primary};
  }
  
  &:visited,
  &:focus,
  &:active {
    color: ${({ theme, $isSelected }) => $isSelected ? theme.palette.link.primary : theme.palette.text.primary};
  }
`

export const MenuContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 12px 0 24px;
`

export const RouteText = styled.div`
  flex-grow: 1;
  display: flex;
  margin-left: 16px;
`

export const RouteIcon = styled.div`
  position: relative;
  display: flex;
`

export const BadgeContainer = styled.div`
  position: absolute;
  display: inline-flex;
  right: -10px;
  top: -5px;
`

export const RouteLinkContainer = styled.div`
  align-items: center;
  width: 100%;
  display: flex;

  .lockIcon {
    transition: opacity 200ms;
  }

  &:hover {
    .lockIcon {
      opacity: 1;
    }
  }
`

export const LockIcon = styled.div<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  opacity: ${({ $isSelected }) => $isSelected ? 1 : 0};
`
