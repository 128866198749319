import {
  DynamicEntityOption,
  FilterDynamicOptionsDataByIdField
} from '../types'
import { BRANCH_FILTER_DYNAMIC_ENTITY_PREFIX } from '@shared/workflows/consts'

interface Params {
  filterDynamicOptionsDataByIdField: FilterDynamicOptionsDataByIdField
}

export const getDynamicEntityOptions = ({
  filterDynamicOptionsDataByIdField
}: Params): DynamicEntityOption[] => {
  return Object.entries(filterDynamicOptionsDataByIdField ?? {})
    .map<DynamicEntityOption>(([idField, { label }]) => ({
      label,
      value: `${BRANCH_FILTER_DYNAMIC_ENTITY_PREFIX}${idField}`
    }))
}
