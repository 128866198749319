import View from './view'
import { connect } from 'react-redux'
import { getUserPreferences } from '@selectors/ui'
import { EMPTY_ARRAY, EMPTY_OBJECT, TABLES } from '../../constants'
import { configureTableColumns } from '@actions/'
import isEmpty from 'lodash/isEmpty'
import keyBy from 'lodash/keyBy'

const mapStateToProps = (state, ownProps) => {
  const { tableKey, options, isScrolling } = ownProps
  const userPreferences = getUserPreferences(state)
  const userTablePreferences = userPreferences[tableKey] || EMPTY_OBJECT
  const { columnsConfiguration = EMPTY_ARRAY } = userTablePreferences
  const { disabledColumns = EMPTY_ARRAY, hiddenColumns = EMPTY_ARRAY } = TABLES[tableKey] ?? EMPTY_OBJECT
  const optionsByColumnKey = keyBy(options || [], 'value')
  const supportedColumns = columnsConfiguration.filter(column => Boolean(optionsByColumnKey[column]))

  return {
    options,
    disabledColumns,
    isScrolling,
    hiddenColumns,
    selectedValues: supportedColumns,
    tableKey,
    columnsOrder: columnsConfiguration,
    loading: isEmpty(options)
  }
}

const mapDispatchToProps = {
  configureTableColumns
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
