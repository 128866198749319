import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getDashboardsData } from '@selectors/dashboards'
import { getCurrentOrg } from '@selectors/org'
import { generateMetricParams, getMetricValue } from '@components/dashboard/widgets/metricWidget/utils'
import { Body2, Button, ButtonType, Card, Stack, Tooltip } from '@toriihq/design-system'
import Placeholder from '@components/placeholder'
import Currency from '@components/currency'
import numeral from 'numeral/numeral'
import { WidgetProps } from '../types'
import * as Style from './style'
import { EMPTY_OBJECT, fieldTypes } from '@root/constants'
import { getEntityDataForWidget } from '@actions/dashboards'
import useDeepCompareEffect from 'use-deep-compare-effect'
import OverflowTooltip from '@components/overflowTooltip'

const MetricWidget = (props: WidgetProps) => {
  const { idDashboard, widget, onViewDataButtonClick, isSelected } = props

  const dispatch = useDispatch()
  const { id: idOrg } = useSelector(getCurrentOrg)

  const dashboardsData = useSelector(getDashboardsData)
  const dashboardData = dashboardsData[idDashboard]
  const widgetsDataById = dashboardData?.widgetsData || EMPTY_OBJECT
  const widgetData = widgetsDataById[widget.id]

  const value = getMetricValue({ metricFunc: widget.displayConfig?.metric?.func, data: widgetData?.data })

  const fieldType = widgetData?.data?.displayConfig?.metric?.field?.type
  const title = fieldType === fieldTypes.currency ? <Currency value={value ?? 0} /> : numeral(value).format('0,0')

  useDeepCompareEffect(() => {
    if (idOrg) {
      const params = generateMetricParams({ metric: widget.displayConfig.metric })
      dispatch(getEntityDataForWidget({
        entityType: widget.entityType,
        idOrg,
        idDashboard,
        idWidget: widget.id,
        filters: widget.dataConfig.filters,
        displayConfig: widget.displayConfig,
        ...params
      }))
    }
  }, [dispatch, idOrg, widget, idDashboard])

  const loading = widgetData?.data?.loading && widgetData?.data?.total === undefined && widgetData?.data?.aggregations === undefined

  return <Card hoverable selected={isSelected} padding={'small'}>
    <Style.CardContent isSelected={isSelected}>
      <Stack direction='column' gap='space-050'>
        <Stack direction='row' gap='space-100' justifyContent='space-between' alignItems='flex-start'>
          <Style.TitleWrapper>
            <Placeholder loading={loading} type='rect' style={Style.Placeholder}>
              <OverflowTooltip label={title}>
                {title}
              </OverflowTooltip>
            </Placeholder>
          </Style.TitleWrapper>
          <Tooltip label='View data'><Button icon='ViewTable' type={ButtonType.tertiary} onClick={() => onViewDataButtonClick(widget)} /></Tooltip>
        </Stack>
        <Body2 color='secondary'>{widget?.displayConfig?.label}</Body2>
      </Stack>
    </Style.CardContent>
  </Card>
}

export default MetricWidget
