import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { getContracts } from '@selectors/contracts'
import { maxBy, minBy, partition } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { getAppContracts } from '@actions/'
import EnableFor from '@components/enableFor'
import { SCOPES } from '@root/constants'
import { AlertBox, AlertBoxType, Body2, Link, Stack } from '@toriihq/design-system'
import { getRenewalAlertState } from '@shared/renewalForcast/renewalStateCalculator'
import { shouldDisplayRenewalForecastButton } from '@shared/renewalForcast/renewalForcastButton'
import { RenewalAlertState } from '@shared/renewalForcast/RenewalAlertState'
import RenewalForecastButton from '@components/renewalForecastButton'
import { RenewalForecastButtonFromLocation } from '@components/renewalForecastButton/types'

const RenewalAlertBox = (props) => {
  const dispatch = useDispatch()
  const { appName, idOrg, idApp, history, appAccounts } = props
  const allContracts = useSelector(getContracts)
  const nextRenewalContract = getNextRenewalContract(idApp, allContracts)
  const alertBoxContent = getAlertBoxContent({
    contract: nextRenewalContract,
    appName,
    idApp,
    history,
    idOrg,
    appAccounts
  })

  useEffect(() => {
    if (idOrg && idApp) {
      dispatch(getAppContracts({ idOrg, idApp }))
    }
  }, [idOrg, idApp, dispatch])

  return (alertBoxContent) ? (
    <AlertBox
      type={AlertBoxType.INFORMATIVE}
      title={alertBoxContent.title}
      description={alertBoxContent.description}
      primaryButton={alertBoxContent.button}
    />
  ) : null
}

const getNextRenewalContract = (idApp, allContracts) => {
  const contracts = allContracts.filter(contract => {
    return contract.idApp === idApp && contract.endDate && contract.status && contract.status.toLowerCase() !== 'closed'
  })

  const [past, future] = partition(contracts, contract => moment(contract.endDate).isBefore(moment()))

  if (future.length) {
    return minBy(future, 'endDate')
  }

  if (past.length) {
    return maxBy(past, 'endDate')
  }

  return null
}

const formatDate = (date) => date ? moment(date).format('D MMMM, YYYY') : ''
interface alertContextResponse {
    button?: JSX.Element
    title: string
    description: JSX.Element
}
export const getAlertBoxContent = ({
  contract,
  appName,
  idApp,
  history,
  idOrg,
  appAccounts
}):alertContextResponse|null => {
  if (!contract || !appName) {
    return null
  }

  const { endDate: renewalDate, cancellationDeadline, autoRenewal } = contract

  const renewalAlertClicked = () => history.push(`/team/${idOrg}/app/${idApp}/contracts`)
  const link = <EnableFor scopes={[SCOPES.CONTRACTS_READ]}><Link onClick={renewalAlertClicked}>View contracts</Link></EnableFor>
  const auditLicenseText = <Body2>Get ahead of your renewal by auditing licenses, identifying unused ones, and generating an accurate license forecast to guide your decisions.</Body2>
  const auditLicenseDescription = <Stack direction='column' gap='space-0'>{auditLicenseText}{link}</Stack>
  const cancelUntilText = `You can cancel until ${formatDate(cancellationDeadline)}.`
  const formattedRenewalDate = formatDate(renewalDate)
  const daysRemainToRenewal = moment(renewalDate).diff(moment(), 'days')
  const autoRenewTitleWithDays = `${appName} will auto renew in ${daysRemainToRenewal} days (${formattedRenewalDate})`
  const autoRenewTitleWithoutDays = `${appName} will auto renew on ${formattedRenewalDate}`
  const isAutoRenewal = autoRenewal === 'Yes'
  const renewalAlertState = getRenewalAlertState({ renewalDate, cancellationDeadline, isAutoRenewal, daysRemainToRenewal })

  const startRenewalForecastButton = <RenewalForecastButton appName={appName} idApp={idApp} appAccounts={appAccounts} idOrg={idOrg} buttonFromLocation={RenewalForecastButtonFromLocation.AppOverview} />
  let result : Omit<alertContextResponse, 'button'> | null = null
  switch (renewalAlertState) {
    case RenewalAlertState.RENEWAL_DATE_PASSED:
      result = {
        title: `${appName}'s renewal date has passed: ${formattedRenewalDate}`,
        description: link
      }
      break
    case RenewalAlertState.CANCELLATION_DEADLINE_PASSED:
      result = {
        title: autoRenewTitleWithoutDays,
        description: <div>Cancellation date has passed: {formatDate(cancellationDeadline)}. {link}</div>
      }
      break
    case RenewalAlertState.LESS_THAN_120_DAYS_DISABLED_AUTO_RENEWAL:
      result = {
        title: `${appName}'s next renewal is in ${daysRemainToRenewal} days (${formattedRenewalDate})`,
        description: auditLicenseDescription
      }
      break
    case RenewalAlertState.LESS_THAN_120_DAYS_NO_CANCELLATION_DEADLINE:
      result = {
        title: autoRenewTitleWithDays,
        description: auditLicenseDescription
      }
      break
    case RenewalAlertState.LESS_THAN_120_DAYS_RENEWAL:
      result = {
        title: autoRenewTitleWithDays,
        description: <Stack direction='column' gap='space-0'><Body2>{cancelUntilText}</Body2>{auditLicenseText}{link}</Stack>
      }
      break
    case RenewalAlertState.MORE_THAN_120_DAYS_DISABLED_AUTO_RENEWAL:
      result = {
        title: `${appName}'s next renewal is on ${formattedRenewalDate}`,
        description: link
      }
      break
    case RenewalAlertState.MORE_THAN_120_DAYS_NO_CANCELLATION_DEADLINE:
      result = {
        title: autoRenewTitleWithoutDays,
        description: link
      }
      break
    case RenewalAlertState.MORE_THAN_120_DAYS_RENEWAL:
      result = {
        title: autoRenewTitleWithoutDays,
        description: <div>{cancelUntilText} {link}</div>
      }
      break
    default:
      return renewalAlertState
  }
  const shouldShowButton = shouldDisplayRenewalForecastButton(renewalAlertState, RenewalForecastButtonFromLocation.AppOverview)
  return { ...result, button: shouldShowButton ? startRenewalForecastButton : undefined }
}

RenewalAlertBox.propTypes = {
  idOrg: PropTypes.number.isRequired,
  idApp: PropTypes.number.isRequired,
  appName: PropTypes.string
}

export default RenewalAlertBox
