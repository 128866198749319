export enum RenewalAlertState {
    RENEWAL_DATE_PASSED= 'RENEWAL_DATE_PASSED',
    CANCELLATION_DEADLINE_PASSED= 'CANCELLATION_DEADLINE_PASSED',

    LESS_THAN_120_DAYS_DISABLED_AUTO_RENEWAL= 'LESS_THAN_120_DAYS_DISABLED_AUTO_RENEWAL',
    LESS_THAN_120_DAYS_NO_CANCELLATION_DEADLINE= 'LESS_THAN_120_DAYS_NO_CANCELLATION_DEADLINE',
    LESS_THAN_120_DAYS_RENEWAL= 'LESS_THAN_120_DAYS_RENEWAL',

    MORE_THAN_120_DAYS_DISABLED_AUTO_RENEWAL= 'MORE_THAN_120_DAYS_DISABLED_AUTO_RENEWAL',
    MORE_THAN_120_DAYS_NO_CANCELLATION_DEADLINE= 'MORE_THAN_120_DAYS_NO_CANCELLATION_DEADLINE',
    MORE_THAN_120_DAYS_RENEWAL= 'MORE_THAN_120_DAYS_RENEWAL'

}
