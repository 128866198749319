const demoDashboard: any = [{
  title: 'SaaS Inventory',
  description: 'Overview of your SaaS portfolio, app breakdown and usage.',
  widgets: [
    {
      'id': 1736343285,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'sanctioned',
              'value': 'sanctioned'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Sanctioned apps',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'primaryOwner',
          'displayActiveUsersCount',
          'expenses',
          'score'
        ]
      }
    },
    {
      'id': 1736343286,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'number',
              'label': 'greater than',
              'value': 'gt'
            },
            'key': {
              'type': 'number',
              'label': 'Number of users',
              'value': 'displayActiveUsersCount'
            },
            'value': 10
          },
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'sanctioned',
              'value': 'sanctioned'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Commonly used apps',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'primaryOwner',
          'displayActiveUsersCount',
          'expenses',
          'score'
        ]
      }
    },
    {
      'id': 1736343287,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'bool',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'New app',
              'value': 'isNew'
            },
            'value': {
              'label': 'Yes',
              'value': true
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Newly discovered apps',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'creationTime',
          'displayActiveUsersCount',
          'score',
          'state',
          'primaryOwner'
        ]
      }
    },
    {
      'id': 1736343288,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'number',
              'label': 'greater than',
              'value': 'gt'
            },
            'key': {
              'type': 'number',
              'label': 'Number of apps used',
              'value': 'activeAppsCount'
            },
            'value': 0
          },
          {
            'op': {
              'type': 'bool',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is External',
              'value': 'isExternal'
            },
            'value': {
              'label': 'No',
              'value': false
            }
          },
          {
            'id': 1737626703880,
            'op': {
              'type': 'bool',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is past user',
              'value': 'isDeletedInIdentitySources'
            },
            'value': {
              'label': 'No',
              'value': false
            }
          }
        ]
      },
      'entityType': 'users',
      'displayConfig': {
        'label': 'Avg. apps per user',
        'metric': {
          'func': 'avg',
          'field': {
            'type': 'number',
            'label': 'Number of apps used',
            'systemKey': 'activeAppsCount'
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'fullName:asc'
        ],
        'fields': [
          'fullName',
          'additionalEmails',
          'activeAppsCount',
          'annualCostConverted'
        ]
      }
    },
    {
      'id': 1736343289,
      'type': 'pieChartWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1737649754498,
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps by source',
        'groupBy': {
          'size': 5,
          'field': {
            'type': 'dropdownMulti',
            'label': 'Sources',
            'systemKey': 'sources'
          },
          'metric': {
            'func': 'total'
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'state',
          'sources',
          'displayActiveUsersCount',
          'score',
          'expenses'
        ]
      }
    },
    {
      'id': 1736343290,
      'type': 'pieChartWidget',
      'dataConfig': {
        'filters': []
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps by state',
        'groupBy': {
          'size': 5,
          'field': {
            'type': 'singleLine',
            'label': 'State',
            'systemKey': 'state'
          },
          'metric': {
            'func': 'total'
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'state',
          'displayActiveUsersCount',
          'score',
          'expenses'
        ]
      }
    },
    {
      'id': 1736343291,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'sanctioned',
              'value': 'sanctioned'
            }
          },
          {
            'id': 1737905370804,
            'op': {
              'type': 'dropdownMulti',
              'label': 'has none of',
              'value': 'noneOf'
            },
            'key': {
              'type': 'dropdownMulti',
              'label': 'Certifications & Compliance',
              'value': 'certificationsAndCompliance'
            },
            'value': [
              {
                'label': 'soc 2',
                'value': 'soc 2'
              },
              {
                'label': 'iso 27001',
                'value': 'iso 27001'
              }
            ]
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps w/o ISO/SOC2 data',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'primaryOwner',
          'certificationsAndCompliance',
          'displayActiveUsersCount',
          'score'
        ]
      }
    },
    {
      'id': 1736343292,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'sanctioned',
              'value': 'sanctioned'
            }
          },
          {
            'id': 1737905669950,
            'op': {
              'type': 'dropdownMulti',
              'label': 'has none of',
              'value': 'noneOf'
            },
            'key': {
              'type': 'dropdownMulti',
              'label': 'Certifications & Compliance',
              'value': 'certificationsAndCompliance'
            },
            'value': [
              {
                'label': 'gdpr',
                'value': 'gdpr'
              }
            ]
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps w/o GDPR data',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'primaryOwner',
          'certificationsAndCompliance',
          'displayActiveUsersCount',
          'score'
        ]
      }
    },
    {
      'id': 1736343293,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'sanctioned',
              'value': 'sanctioned'
            }
          },
          {
            'id': 1737905812210,
            'op': {
              'label': 'is not set',
              'value': 'isNotSet'
            },
            'key': {
              'type': 'user',
              'label': 'Primary App Owner',
              'value': 'primaryOwner'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps without owners',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'creationTime',
          'displayActiveUsersCount',
          'score',
          'expenses'
        ]
      }
    },
    {
      'id': 1736343294,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1737628364676,
            'op': {
              'type': 'number',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'number',
              'label': 'Number of used days',
              'value': 'score'
            },
            'value': 0
          },
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'sanctioned',
              'value': 'sanctioned'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Potentially unused apps',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'primaryOwner',
          'displayActiveUsersCount',
          'score',
          'expenses'
        ]
      }
    },
    {
      'id': 1736343295,
      'type': 'pieChartWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1737649754498,
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          },
          {
            'id': 1737650028499,
            'op': {
              'type': 'bool',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Has integration',
              'value': 'hasIntegration'
            },
            'value': {
              'label': 'Yes',
              'value': true
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Potential integrations connected',
        'groupBy': {
          'size': 5,
          'field': {
            'type': 'bool',
            'label': 'Is connected',
            'systemKey': 'isConnected'
          },
          'metric': {
            'func': 'total'
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'primaryOwner',
          'displayActiveUsersCount',
          'expenses',
          'score',
          'isConnected'
        ]
      }
    },
    {
      'id': 1736343296,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is Past User',
              'value': 'isDeletedInIdentitySources'
            },
            'value': {
              'label': 'No',
              'value': false
            }
          },
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'text',
              'label': 'User Type',
              'value': 'userType'
            },
            'value': {
              'label': 'employee',
              'value': 'employee'
            }
          }
        ]
      },
      'entityType': 'users',
      'displayConfig': {
        'label': 'Active employees (Not in MVP)',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'fullName:asc'
        ],
        'fields': [
          'fullName',
          'activeAppsCount',
          'annualCostConverted',
          'additionalEmails'
        ]
      }
    },
    {
      'id': 1736343297,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is External',
              'value': 'isExternal'
            },
            'value': {
              'label': 'No',
              'value': false
            }
          },
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is Past User',
              'value': 'isDeletedInIdentitySources'
            },
            'value': {
              'label': 'Yes',
              'value': true
            }
          }
        ]
      },
      'entityType': 'users',
      'displayConfig': {
        'label': 'Past users with app access (Not in MVP)',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'fullName:asc'
        ],
        'fields': [
          'firstName',
          'lastName',
          'email',
          'isExternal',
          'creationTime',
          'idRole',
          'idOrg',
          'status',
          'lifecycleStatus',
          'isDeletedInIdentitySources',
          'identitySourcesDeletionTime',
          'activeAppsCount',
          'photoUrl',
          'annualCostConverted',
          'additionalEmails'
        ]
      }
    },
    {
      'id': 1736343298,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is past user',
              'value': 'isDeletedInIdentitySources'
            },
            'value': {
              'label': 'No',
              'value': false
            }
          },
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is external',
              'value': 'isExternal'
            },
            'value': {
              'label': 'No',
              'value': false
            }
          },
          {
            'op': {
              'type': 'number',
              'label': 'greater than',
              'value': 'gt'
            },
            'key': {
              'type': 'number',
              'label': 'Number of apps used',
              'value': 'activeAppsCount'
            },
            'value': 0
          },
          {
            'id': 1733221913007,
            'op': {
              'type': 'relativeDate',
              'label': 'is less than',
              'value': 'relativeDateLess'
            },
            'key': {
              'type': 'date',
              'idApp': 3506,
              'label': 'Hibob Start Date',
              'value': 'hibob_startDate'
            },
            'value': {
              'type': {
                'label': 'days ago',
                'value': 'ago'
              },
              'number': '90'
            }
          }
        ]
      },
      'entityType': 'users',
      'displayConfig': {
        'label': 'Recent hires (Not in MVP)',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'fullName:asc'
        ],
        'fields': [
          'firstName',
          'lastName',
          'email',
          'isExternal',
          'creationTime',
          'idRole',
          'idOrg',
          'status',
          'lifecycleStatus',
          'isDeletedInIdentitySources',
          'identitySourcesDeletionTime',
          'activeAppsCount',
          'photoUrl',
          'annualCostConverted',
          'additionalEmails'
        ]
      }
    },
    {
      'id': 1736343299,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'relativeDate',
              'label': 'is less than',
              'value': 'relativeDateLess'
            },
            'key': {
              'type': 'date',
              'idApp': null,
              'label': 'End date',
              'value': 'endDate'
            },
            'value': {
              'type': {
                'label': 'days from now',
                'value': 'from'
              },
              'number': '30'
            }
          }
        ]
      },
      'entityType': 'contracts',
      'displayConfig': {
        'label': 'Upcoming contract renewals (Not in MVP)',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'createdBy',
          'startDate',
          'endDate'
        ]
      }
    },
    {
      'id': 17363432100,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': []
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Applications last 12 months expenses Sum (Not in MVP)',
        'metric': {
          'func': 'sum',
          'field': {
            'type': 'currency',
            'label': 'Expenses (last 12 months)',
            'systemKey': 'expenses'
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'category'
        ]
      }
    },
    {
      'id': 17363432101,
      'type': 'pieChartWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is Past User',
              'value': 'isDeletedInIdentitySources'
            },
            'value': {
              'label': 'No',
              'value': false
            }
          },
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'text',
              'label': 'User Type',
              'value': 'userType'
            },
            'value': {
              'label': 'employee',
              'value': 'employee'
            }
          }
        ]
      },
      'entityType': 'users',
      'displayConfig': {
        'label': 'Active users by department (Not in MVP)',
        'groupBy': {
          'size': 5,
          'field': {
            'type': 'singleLine',
            'label': 'Google Workspace Department',
            'systemKey': 'gsuite_department'
          },
          'metric': {
            'func': 'total'
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'fullName:asc'
        ],
        'fields': [
          'fullName',
          'activeAppsCount',
          'annualCostConverted',
          'additionalEmails'
        ]
      }
    },
    {
      'id': 17363432102,
      'type': 'pieChartWidget',
      'dataConfig': {
        'filters': []
      },
      'entityType': 'contracts',
      'displayConfig': {
        'label': 'Contracts grouped by Status (Not in MVP)',
        'groupBy': {
          'size': 5,
          'field': {
            'type': 'dropdown',
            'label': 'Status',
            'systemKey': 'status'
          },
          'metric': {
            'func': 'total'
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'createdBy',
          'startDate',
          'endDate'
        ]
      }
    },
    {
      'id': 17363432103,
      'type': 'barChartWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps grouped by Tag (Not in MVP)',
        'groupBy': {
          'size': 5,
          'field': {
            'type': 'dropdownMulti',
            'label': 'Tags',
            'systemKey': 'tags'
          },
          'metric': {
            'func': 'total'
          }
        },
        'barOrientation': 'vertical'
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'state',
          'tags',
          'displayActiveUsersCount',
          'score',
          'expenses',
          'primaryOwner'
        ]
      }
    },
    {
      'id': 17363432104,
      'type': 'barChartWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps by category (Not in MVP)',
        'groupBy': {
          'size': 5,
          'field': {
            'type': 'dropdown',
            'label': 'Category',
            'systemKey': 'category'
          },
          'metric': {
            'func': 'total'
          }
        },
        'barOrientation': 'horizontal'
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'state',
          'category',
          'displayActiveUsersCount',
          'score',
          'expenses',
          'primaryOwner'
        ]
      }
    },
    {
      'id': 17363432105,
      'type': 'pieChartWidget',
      'dataConfig': {
        'filters': []
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Applications group by Category total expenses (Not in MVP)',
        'groupBy': {
          'size': 5,
          'field': {
            'type': 'dropdown',
            'label': 'Category',
            'systemKey': 'category'
          },
          'metric': {
            'func': 'sum',
            'field': {
              'type': 'currency',
              'label': 'Expenses (last 12 months)',
              'systemKey': 'expenses'
            }
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'category'
        ]
      }
    },
    {
      'id': 17363432106,
      'type': 'pieChartWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is Past User',
              'value': 'isDeletedInIdentitySources'
            },
            'value': {
              'label': 'No',
              'value': false
            }
          },
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'text',
              'label': 'User Type',
              'value': 'userType'
            },
            'value': {
              'label': 'employee',
              'value': 'employee'
            }
          }
        ]
      },
      'entityType': 'users',
      'displayConfig': {
        'label': 'The total cost of active users by department (Not in MVP)',
        'groupBy': {
          'size': 5,
          'field': {
            'type': 'singleLine',
            'label': 'Google Workspace Department',
            'systemKey': 'gsuite_department'
          },
          'metric': {
            'func': 'sum',
            'field': {
              'type': 'currency',
              'label': 'Annual cost',
              'systemKey': 'annualCostConverted'
            }
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'fullName:asc'
        ],
        'fields': [
          'fullName',
          'activeAppsCount',
          'annualCostConverted',
          'additionalEmails'
        ]
      }
    },
    {
      'id': 17363432107,
      'type': 'pieChartWidget',
      'dataConfig': {
        'filters': []
      },
      'entityType': 'contracts',
      'displayConfig': {
        'label': 'The total amount of contracts grouped by Status (Not in MVP)',
        'groupBy': {
          'size': 5,
          'field': {
            'type': 'dropdown',
            'label': 'Status',
            'systemKey': 'status'
          },
          'metric': {
            'func': 'sum',
            'field': {
              'type': 'currency',
              'label': 'Contract value',
              'systemKey': 'amount'
            }
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'createdBy',
          'startDate',
          'endDate'
        ]
      }
    },
    {
      'id': 17363432108,
      'type': 'barChartWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps discovery by quarter (Not in MVP)',
        'groupBy': {
          'size': 5,
          'field': {
            'type': 'date',
            'label': 'Discovery date',
            'systemKey': 'creationTime'
          },
          'metric': {
            'func': 'total'
          },
          'datePeriod': 'quarterly'
        },
        'barOrientation': 'horizontal'
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'state',
          'tags',
          'displayActiveUsersCount',
          'score',
          'expenses',
          'primaryOwner'
        ]
      }
    },
    {
      'id': 17363432109,
      'type': 'pieChartWidget',
      'dataConfig': {
        'filters': []
      },
      'entityType': 'contracts',
      'displayConfig': {
        'label': 'Contract value by end month (Not in MVP)',
        'groupBy': {
          'size': 5,
          'field': {
            'type': 'datePicker',
            'label': 'End date',
            'systemKey': 'endDate'
          },
          'metric': {
            'func': 'sum',
            'field': {
              'type': 'currency',
              'label': 'Contract value',
              'systemKey': 'amount'
            }
          },
          'datePeriod': 'monthly'
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'createdBy',
          'startDate',
          'endDate',
          'amount'
        ]
      }
    }
  ],
  layoutConfig: [
    {
      'h': 2,
      'i': 1736343285,
      'w': 3,
      'x': 0,
      'y': 0
    },
    {
      'h': 2,
      'i': 1736343286,
      'w': 3,
      'x': 3,
      'y': 0
    },
    {
      'h': 2,
      'i': 1736343287,
      'w': 3,
      'x': 6,
      'y': 0
    },
    {
      'h': 2,
      'i': 1736343288,
      'w': 3,
      'x': 9,
      'y': 0
    },
    {
      'h': 6,
      'i': 1736343289,
      'w': 6,
      'x': 0,
      'y': 2
    },
    {
      'h': 6,
      'i': 1736343290,
      'w': 6,
      'x': 6,
      'y': 2
    },
    {
      'h': 2,
      'i': 1736343291,
      'w': 3,
      'x': 6,
      'y': 8
    },
    {
      'h': 2,
      'i': 1736343292,
      'w': 3,
      'x': 9,
      'y': 8
    },
    {
      'h': 2,
      'i': 1736343293,
      'w': 3,
      'x': 6,
      'y': 10
    },
    {
      'h': 2,
      'i': 1736343294,
      'w': 3,
      'x': 9,
      'y': 10
    },
    {
      'h': 6,
      'i': 1736343295,
      'w': 6,
      'x': 0,
      'y': 8
    },
    {
      'h': 2,
      'i': 1736343296,
      'w': 3,
      'x': 6,
      'y': 24
    },
    {
      'h': 2,
      'i': 1736343297,
      'w': 3,
      'x': 9,
      'y': 22
    },
    {
      'h': 2,
      'i': 1736343298,
      'w': 3,
      'x': 9,
      'y': 24
    },
    {
      'h': 2,
      'i': 1736343299,
      'w': 3,
      'x': 6,
      'y': 22
    },
    {
      'h': 2,
      'i': 17363432100,
      'w': 6,
      'x': 6,
      'y': 20
    },
    {
      'h': 6,
      'i': 17363432101,
      'w': 6,
      'x': 0,
      'y': 32
    },
    {
      'h': 6,
      'i': 17363432102,
      'w': 6,
      'x': 0,
      'y': 20
    },
    {
      'h': 6,
      'i': 17363432103,
      'w': 6,
      'x': 6,
      'y': 32
    },
    {
      'h': 6,
      'i': 17363432104,
      'w': 12,
      'x': 0,
      'y': 14
    },
    {
      'h': 6,
      'i': 17363432105,
      'w': 6,
      'x': 0,
      'y': 26
    },
    {
      'h': 6,
      'i': 17363432106,
      'w': 6,
      'x': 6,
      'y': 26
    },
    {
      'h': 6,
      'i': 17363432107,
      'w': 12,
      'x': 0,
      'y': 38
    },
    {
      'h': 6,
      'i': 17363432108,
      'w': 6,
      'x': 0,
      'y': 44
    },
    {
      'h': 6,
      'i': 17363432109,
      'w': 6,
      'x': 6,
      'y': 44
    }
  ]
}]

export default demoDashboard
