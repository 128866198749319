import React, { ReactElement } from 'react'
import Input from '@components/form/input'
import { ErrorSpan, FromGroup } from './styles'
import FormGroup from '@components/form/formGroup/view'
import { Spacer } from '@toriihq/design-system'

interface Props {
  targetText: string
  value: string
  setValue: (input: string) => void
  label: string
}

const InputChecker = ({
  targetText,
  value,
  setValue,
  label
}: Props): ReactElement => {
  const shouldShowError = value && !(value === targetText)

  return (
    <FormGroup label={label} styles={FromGroup}>
      <Input
        value={value}
        onChange={e => setValue(e.target.value)}
        autoFocus
        placeholder={targetText}
      />
      {shouldShowError &&
      <Spacer top='space-050' >
        <ErrorSpan>{`Type “${targetText}” in all-caps`}</ErrorSpan>
      </Spacer>}
    </FormGroup>
  )
}

export default InputChecker
