import React, { ComponentType, Fragment, ReactElement } from 'react'
import { Field, Form } from 'react-final-form'
import Select from '@components/select'
import { formFieldTypes } from '@root/constants'
import { Divider, FormElement, Input, Spacer, Subtitle1 } from '@toriihq/design-system'
import Filters from './filters'
import { ops } from '@root/lenses/filters'
import { FormFieldWrapper } from './styles'
import { noop } from 'lodash'
import { getValidFilters } from '@shared/filters'

const renderSelectComponent = React.memo(({ onFieldChange, name, label, input, options, meta, ...props }:any) => {
  return (
    <FormElement label={label} errorMessage={meta.touched && meta.error}>
      <Select
        options={options}
        labelKey='label'
        valueKey='value'
        clearable={false}
        searchable
        name={name}
        {...input}
        {...props}
        onChange={(selection : { value: string }) => {
          input.onChange(selection.value)
          onFieldChange(input.name, selection.value)
        }}
      />
    </FormElement>
  )
})

const renderFiltersComponent = React.memo(({ onFieldChange, filtersOptions, fieldOptionsValuesPerKey, fetchFieldValues, label, input, meta }:any) => {
  return (
    <FormElement label={label} errorMessage={meta.touched && meta.error}>
      <Filters
        optionsKey={filtersOptions}
        optionsValuesPerKey={fieldOptionsValuesPerKey}
        filters={input.value}
        fetchFieldValues={fetchFieldValues}
        onFiltersChange={(filters) => {
          input.onChange(filters)
          onFieldChange(input.name, filters)
        }}
        onFiltersPopupClose={(filters) => {
          onFieldChange(input.name, getValidFilters(filters))
        }} />
    </FormElement>
  )
})

const renderInputComponent = React.memo(({ onFieldChange, label, input, meta }: any) => {
  return (
    <FormElement label={label} errorMessage={meta.touched && meta.error}>
      <Input
        {...input}
        onBlur={(e) => {
          input.onChange(e.target.value)
          onFieldChange(input.name, e.target.value)
        }}
      />
    </FormElement>
  )
})

const EditWidgetForm = React.memo((props: any): ReactElement => {
  const { sections, initialValues, onFieldChange, filtersOptions, fieldOptionsValuesPerKey, fetchFieldValues } = props

  const formFieldTypeToConfigMap: Record<string, { component: ComponentType<any> }> = {
    [formFieldTypes.singleLine]: {
      component: renderInputComponent
    },
    [formFieldTypes.dropdown]: {
      component: renderSelectComponent
    },
    [formFieldTypes.filters]: {
      component: renderFiltersComponent
    }
  }

  const shouldShowField = (field, formValues) => {
    const condition = field.showOnFieldSpecificCondition

    if (!condition) {
      return true
    }

    const { comparisonType, fieldKey, op, value } = condition
    const dependsOnFieldValue = formValues[fieldKey]

    if (comparisonType === 'type') {
      if (dependsOnFieldValue?.type) {
        return op === ops.anyOf
          ? value.includes(dependsOnFieldValue.type)
          : dependsOnFieldValue.type === value
      }
      return true
    }

    return op === ops.equals
      ? dependsOnFieldValue === value
      : dependsOnFieldValue !== value
  }

  return (
    <Form
      onSubmit={noop}
      initialValues={initialValues}
      render={(formProps) => {
        const { handleSubmit, values } = formProps

        return (
          <Fragment>
            <form onSubmit={handleSubmit}>
              {sections.map((section, index) => {
                const isLastSection = index === sections.length - 1

                return <Fragment key={section.key}>
                  {section.label && <Spacer bottom='space-100'>
                    <Subtitle1>{section.label}</Subtitle1>
                  </Spacer>}

                  {section.fields.map((field) => {
                    if (!shouldShowField(field, values)) {
                      return null
                    }

                    return (
                      <FormFieldWrapper key={field.key}>
                        <Field
                          name={field.key}
                          label={field.label}
                          component={formFieldTypeToConfigMap[field.type].component}
                          options={field.options}
                          values={field.values}
                          filtersOptions={filtersOptions}
                          fieldOptionsValuesPerKey={fieldOptionsValuesPerKey}
                          fetchFieldValues={fetchFieldValues}
                          onFieldChange={onFieldChange}
                          isRequired
                          disabled={field.disabled}
                        />
                      </FormFieldWrapper>
                    )
                  })}

                  {!isLastSection && (
                    <FormFieldWrapper>
                      <Divider orientation='Vertical' />
                    </FormFieldWrapper>
                  )}
                </Fragment>
              }) }
            </form>
          </Fragment>
        )
      }}
    />

  )
})

export default EditWidgetForm
