import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDashboardWidgetEditConfigDrawer } from '@selectors/ui'
import { filterTable } from '@store/actions'
import { BAR_ORIENTATION, DATE_PERIOD, Metric, METRIC_FUNC, Widget } from '@reducers/dashboards/types'
import { fieldTypes } from '@root/constants'
import EditWidgetForm from './editWidgetForm'
import { DATE_FIELD_TYPES, getWidgetTableKey, useGetWidgetFieldsDataByEntityType } from '../widgets/shared'
import { isNil, omitBy } from 'lodash'
import Analytics, { ENTITY_TYPE } from '@components/dashboard/analytics'
import { DashboardEditWidgetProps } from './types'
import { DROPDOWN_OPTIONS_TYPE } from '../widgets/types'

const DashboardEditWidget = (props: DashboardEditWidgetProps): ReactElement | null => {
  const dispatch = useDispatch()

  const { dashboardName } = props

  const { sections, widget, onWidgetUpdate } = useSelector(getDashboardWidgetEditConfigDrawer)

  const [updatedWidget, setUpdatedWidget] = useState(widget)

  useEffect(() => {
    if (widget) {
      setUpdatedWidget(widget)
    }
  }, [widget])

  const widgetTableKey = getWidgetTableKey(widget)

  const onFieldChange = async (fieldKey: string, fieldValue: string) => {
    Analytics.updateWidgetConfiguration({ dashboardName, widgetTitle: updatedWidget?.displayConfig.label, fieldName: fieldKey, entityType: ENTITY_TYPE.DRAWER })
    const newDisplayConfig: Widget['displayConfig'] = { ...updatedWidget.displayConfig }

    let filters = updatedWidget.dataConfig.filters
    if (fieldKey === 'filters') {
      filters = fieldValue
      await dispatch(filterTable({ tableKey: widgetTableKey, filters: fieldValue, supportViews: false, shareViewPreferences: false }))
    }

    if (fieldKey === 'label') {
      newDisplayConfig.label = fieldValue
    }

    if (fieldKey === 'barOrientation' && fieldValue) {
      newDisplayConfig.barOrientation = fieldValue as BAR_ORIENTATION
    }

    if (fieldKey === 'groupBy' && fieldValue) {
      const field = fieldsOptions.find((field) => field.value === fieldValue)
      const newField = { systemKey: fieldValue, type: field.type, label: field.label }
      newDisplayConfig.groupBy = {
        ...newDisplayConfig.groupBy,
        field: newField,
        size: updatedWidget.displayConfig?.groupBy?.size ?? 5,
        metric: updatedWidget.displayConfig?.groupBy?.metric
      }

      if (DATE_FIELD_TYPES.includes(newField.type)) {
        newDisplayConfig.groupBy.datePeriod = updatedWidget.displayConfig?.groupBy?.datePeriod || fieldsDefaultValue.datePeriod
      } else {
        newDisplayConfig.groupBy.datePeriod = undefined
      }
    }

    if (fieldKey === 'datePeriod' && fieldValue) {
      if (newDisplayConfig.groupBy) {
        newDisplayConfig.groupBy = {
          ...newDisplayConfig.groupBy,
          datePeriod: fieldValue as DATE_PERIOD
        }
      }
    }

    if (fieldKey === 'metricFunc') {
      const existingMetricFunc = updatedWidget.displayConfig?.groupBy?.metric?.func ?? updatedWidget.displayConfig?.metric?.func
      const existingMetricField = updatedWidget.displayConfig?.groupBy?.metric?.field ?? updatedWidget.displayConfig?.metric?.field
      const newMetricConfig = { func: fieldValue, field: existingMetricField } as Metric

      if (fieldValue !== METRIC_FUNC.TOTAL && existingMetricFunc !== fieldValue) {
        if (!existingMetricField?.systemKey) {
          const field = fieldsOptions.find((field) => field.value === fieldsDefaultValue?.metricField)
          newMetricConfig.field = { systemKey: fieldsDefaultValue?.metricField, type: field.type, label: field.label }
        }
      }

      if (fieldValue === METRIC_FUNC.TOTAL) {
        newMetricConfig.field = undefined
      }

      if (updatedWidget.displayConfig.groupBy) {
        newDisplayConfig.groupBy = { ...updatedWidget.displayConfig.groupBy, metric: newMetricConfig }
      } else {
        newDisplayConfig.metric = newMetricConfig
      }
    }

    if (fieldKey === 'metricField' && fieldValue) {
      const field = fieldsOptions.find((field) => field.value === fieldValue)
      const newMetricField = { systemKey: fieldValue, type: field.type, label: field.label }

      if (updatedWidget.displayConfig.groupBy?.metric?.func && updatedWidget.displayConfig.groupBy?.metric?.func !== METRIC_FUNC.TOTAL) {
        newDisplayConfig.metric = undefined
        newDisplayConfig.groupBy = {
          field: updatedWidget.displayConfig.groupBy.field,
          datePeriod: updatedWidget.displayConfig.groupBy.datePeriod,
          size: updatedWidget.displayConfig.groupBy.size,
          metric: {
            func: updatedWidget.displayConfig.groupBy?.metric.func,
            field: newMetricField
          }
        }
      } else if (updatedWidget.displayConfig.metric?.func && updatedWidget.displayConfig.metric?.func !== METRIC_FUNC.TOTAL) {
        newDisplayConfig.metric = {
          func: updatedWidget.displayConfig.metric.func,
          field: newMetricField
        }
      }
    }

    const newWidget = {
      ...updatedWidget,
      displayConfig: omitBy(newDisplayConfig, isNil),
      dataConfig: {
        ...updatedWidget.dataConfig,
        filters
      }
    }

    setUpdatedWidget(newWidget)
    onWidgetUpdate({
      updatedWidget: newWidget
    })
  }

  const { fieldsOptions, filtersOptions, fieldOptionsValuesPerKey, fetchFieldValues } = useGetWidgetFieldsDataByEntityType(widget.entityType)

  const GROUP_BY_TYPES_TO_EXCLUDE = [fieldTypes.currency, fieldTypes.fileUpload]
  const NUMERIC_TYPES = [fieldTypes.number, fieldTypes.currency]

  const getDropdownOptionsByType = (type) => {
    if (!type || !fieldsOptions) {
      return fieldsOptions
    }
    switch (type) {
      case DROPDOWN_OPTIONS_TYPE.GROUP_BY:
        return fieldsOptions.filter(field => {
          const isNestedField = field.value.includes('.')
          return !GROUP_BY_TYPES_TO_EXCLUDE.includes(field.type) &&
          !(DATE_FIELD_TYPES.includes(field.type) && isNestedField)
        })
      case DROPDOWN_OPTIONS_TYPE.NUMERIC:
        return fieldsOptions.filter(field => NUMERIC_TYPES.includes(field.type))
      default:
        return fieldsOptions
    }
  }

  const sectionWithFieldOptions = sections.map((section) => {
    return {
      ...section,
      fields: section.fields.map((field) => {
        let options
        if (field.options) {
          options = field.options
        } else if (field.type === fieldTypes.dropdown) {
          options = getDropdownOptionsByType(field.dropdownOptionsType)
        }

        return {
          ...field,
          options
        }
      })
    }
  })

  const fieldsDefaultValue = sectionWithFieldOptions.reduce((acc, section) => {
    section.fields.forEach((field) => {
      acc[field.key] = field.options?.[0]?.value
    })
    return acc
  }, {})

  if (!widget.displayConfig) {
    return null
  } else {
    const initialFormValues = {
      label: widget.displayConfig.label,
      entityType: widget.entityType,
      widgetType: widget.type,
      groupBy: widget.displayConfig.groupBy?.field,
      datePeriod: widget.displayConfig.groupBy?.datePeriod,
      barOrientation: widget.displayConfig?.barOrientation,
      metricFunc: widget.displayConfig?.groupBy?.metric?.func ?? widget.displayConfig?.metric?.func ?? METRIC_FUNC.TOTAL,
      metricField: widget.displayConfig?.groupBy?.metric?.field?.systemKey ?? widget.displayConfig?.metric?.field?.systemKey,
      filters: widget.dataConfig?.filters
    }

    return <EditWidgetForm
      sections={sectionWithFieldOptions}
      onFieldChange={onFieldChange}
      initialValues={initialFormValues}
      filtersOptions={filtersOptions}
      fieldOptionsValuesPerKey={fieldOptionsValuesPerKey}
      fetchFieldValues={fetchFieldValues}
    />
  }
}

export default DashboardEditWidget
