import { AGGREGATION_TYPES, Metric, METRIC_FUNC, WidgetData } from '@reducers/dashboards/types'

export const generateMetricParams = ({ metric }: { metric: Metric | undefined }): {} | { withoutContent: boolean } | { limit: number, aggs: object } => {
  if (!metric) {
    return {}
  }

  const { func, field } = metric

  if (func === METRIC_FUNC.TOTAL) {
    return { withoutContent: true }
  }

  if ([METRIC_FUNC.MIN, METRIC_FUNC.MAX, METRIC_FUNC.AVG, METRIC_FUNC.SUM].includes(func) && field) {
    return {
      limit: 0,
      aggs: {
        field: field.systemKey,
        aggregationType: AGGREGATION_TYPES.METRIC,
        options: {
          metricFunction: func
        }
      }
    }
  }

  return {}
}

export const getMetricValue = ({ metricFunc, data }: { metricFunc?: METRIC_FUNC, data?: WidgetData['data'] }): number => {
  if (metricFunc === METRIC_FUNC.TOTAL) {
    return data?.total ?? 0
  }

  if (metricFunc && [METRIC_FUNC.MIN, METRIC_FUNC.MAX, METRIC_FUNC.AVG, METRIC_FUNC.SUM].includes(metricFunc)) {
    return (data?.aggregations as number) ?? 0
  }

  return 0
}
