import { ReactElement, useEffect } from 'react'
import ToriiPopup from '../../popups/ToriiPopupV2'
import { useDispatch, useSelector } from 'react-redux'
import { getDashboardsData } from '@selectors/dashboards'
import { getIdOrg } from '@selectors/org'
import { EMPTY_OBJECT, GET_DRILL_DOWN_DATA_FOR_WIDGET, ITEMS_PER_PAGE } from '@root/constants'
import Table from '@components/table'
import * as Style from './style'
import uniq from 'lodash/uniq'
import { getWidgetTableKey } from '@components/dashboard/widgets/shared'
import { sortStringFromArray } from '@shared/utils'
import { ENTITY_TYPES } from '@reducers/dashboards/types'
import AppDataPopup from '@components/dashboard/dashboardWidgetDataPopup/appDataPopup'
import UserDataPopup from '@components/dashboard/dashboardWidgetDataPopup/userDataPopup'
import ContractDataPopup from '@components/dashboard/dashboardWidgetDataPopup/contractDataPopup'
import { getEntityDataForWidget } from '@actions/dashboards'
import { DashboardWidgetDataPopupProps } from './types'
import Analytics, { ENTITY_TYPE } from '@components/dashboard/analytics'

const USER_REQUIRED_FIELDS = ['firstName', 'lastName', 'email', 'isExternal', 'creationTime', 'idRole', 'idOrg', 'status', 'lifecycleStatus', 'isDeletedInIdentitySources', 'identitySourcesDeletionTime', 'activeAppsCount', 'photoUrl', 'annualCostConverted', 'additionalEmails']

const DashboardWidgetDataPopup = (props: DashboardWidgetDataPopupProps): ReactElement => {
  const dispatch = useDispatch()

  const { isOpen: isDashboardWidgetDataPopupOpen, isEditMode, idDashboard, widget, onWidgetUpdate, onCloseAction, dashboardName } = props
  const { id: idWidget } = widget

  const idOrg = useSelector(getIdOrg)
  const dashboardsData = useSelector(getDashboardsData)

  const dashboardData = dashboardsData[idDashboard]
  const widgetsDataById = dashboardData?.widgetsData ?? EMPTY_OBJECT
  const widgetData = widgetsDataById[idWidget]
  const { rows = [], loading, total: totalResults } = widgetData?.drillDownData ?? {}

  const loadingData = loading && rows.length === 0

  const getDrillDownData = async ({ limit = ITEMS_PER_PAGE, offset = 0, reset = false }) => {
    await dispatch(getEntityDataForWidget({
      actionTarget: GET_DRILL_DOWN_DATA_FOR_WIDGET,
      entityType: widget.entityType,
      idOrg,
      idWidget,
      idDashboard,
      filters: widget.dataConfig.filters,
      // todo: can we send the list of fields for app and contract?
      fields: widget.entityType === ENTITY_TYPES.USERS ? uniq([...USER_REQUIRED_FIELDS, ...widget.drillDownConfig.fields]) : undefined,
      sort: widget.drillDownConfig.sort,
      offset,
      reset,
      limit: limit
    }))
  }

  const fetchData = (reset = false) => {
    getDrillDownData({ offset: reset ? 0 : rows.length, reset })
  }

  useEffect(() => {
    if (idOrg && idWidget) {
      fetchData(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, idOrg, idWidget, widget, idDashboard])

  const onColumnSelectionChange = (fields) => {
    onWidgetUpdate({ updatedWidget: { ...widget, drillDownConfig: { ...widget.drillDownConfig, fields } } })
  }

  const onFilterChange = (filters) => {
    Analytics.updateWidgetConfiguration({ dashboardName, widgetTitle: widget?.displayConfig.label, fieldName: 'filters', entityType: ENTITY_TYPE.POPUP })
    onWidgetUpdate({ updatedWidget: { ...widget, dataConfig: { ...widget.dataConfig, filters } } })
  }

  const onSortChange = (sort) => {
    onWidgetUpdate({ updatedWidget: { ...widget, drillDownConfig: { ...widget.drillDownConfig, sort: sortStringFromArray(sort) } } })
  }

  const renderFunc = ({ columns, filtersOptions, fieldValues, configurableColumnsOptions, fetchFieldValues, exportToCsv }) => {
    const handleExportToCsv = (...args) => {
      Analytics.clickOnExportCSV({ dashboardName, widgetTitle: widget?.displayConfig.label })
      exportToCsv(...args)
    }

    return (
      <ToriiPopup isOpen={isDashboardWidgetDataPopupOpen} styles={Style.Popup} onCloseAction={onCloseAction}>
        <ToriiPopup.Header header={widget.displayConfig.label} />
        <ToriiPopup.Content contentAreaStyle={Style.PopupContent}>
          <Table
            tableKey={getWidgetTableKey(widget)}
            data={rows}
            columns={columns}
            loading={loadingData}
            filterable
            filtersOptions={filtersOptions}
            filterOptionsValuesPerKey={fieldValues}
            configurableColumnsOptions={configurableColumnsOptions}
            configurableColumns
            disableFilterEdit={!isEditMode ? { tooltipFiltersMessage: 'Enter edit mode to update filters', tooltipColumnsMessage: 'Enter edit mode to update columns' } : undefined}
            draggable={isEditMode}
            sortable={isEditMode}
            onColumnSelectionChange={onColumnSelectionChange}
            onColumnOrderChange={onColumnSelectionChange}
            onSortedChangeCB={onSortChange}
            fetchFieldValues={fetchFieldValues}
            manual
            exportable
            exportFunction={handleExportToCsv}
            onFilterChange={onFilterChange}
            overrideTheadStyle={Style.Thead}
            fetchData={fetchData}
            totalCount={totalResults}
            scrollObjectId='contentArea'
            loadingMore={loading}
          />
        </ToriiPopup.Content>
      </ToriiPopup>
    )
  }

  switch (widget.entityType) {
    case ENTITY_TYPES.USERS:
      return <UserDataPopup renderFunc={renderFunc} widget={widget} widgetData={widgetData} />
    case ENTITY_TYPES.APPS:
      return <AppDataPopup renderFunc={renderFunc} widget={widget} widgetData={widgetData} />
    case ENTITY_TYPES.CONTRACTS:
      return <ContractDataPopup renderFunc={renderFunc} widget={widget} widgetData={widgetData} />
    default:
      return <div />
  }
}

export default DashboardWidgetDataPopup
