import View from './view'
import { connect } from 'react-redux'
import { getMe } from '@pages/login/actions'
import { getSelf, isPaidPlanExpired } from '@selectors/me'
import { getOrg, getSupportedFeatures } from '@store/actions'
import { getCurrentOrg, getSupportedFeatures as getSupportedFeaturesSelector, isLoadedSupportedFeatures } from '@selectors/org'

const mapStateToProps = (state, ownProps) => {
  const me = getSelf(state)
  const org = getCurrentOrg(state)
  const paidPlanExpired = isPaidPlanExpired(state)
  const idOrg = parseInt(ownProps.computedMatch.params.idOrg, 10)
  const supportedFeatures = getSupportedFeaturesSelector(state)
  const isLoadedFeatures = isLoadedSupportedFeatures(state)

  return {
    authState: me.authState,
    me,
    org,
    idOrg: isNaN(idOrg) ? null : idOrg,
    paidPlanExpired,
    supportedFeatures,
    isLoadedFeatures
  }
}

const mapDispatchToProps = {
  getMe,
  getOrg,
  getSupportedFeatures
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
