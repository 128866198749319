import View from './view'
import { connect } from 'react-redux'
import { getConstantServicesByIdApp, getSyncStatus } from '@selectors/services'
import { getAppAccountsByIdApp } from '@selectors/apps/utils'
import { getAppAccountsValidForAppRenewal } from '@shared/renewalForcast/utils'
import { getIdOrg } from '@selectors/org'

const mapStateToProps = (state, ownProps) => {
  const { app } = ownProps
  const idOrg = getIdOrg(state)

  const accountSyncStatuses = getSyncStatus(state)
  const allAppAccounts = getAppAccountsByIdApp({ idApp: app.id, accountSyncStatuses })
  const serviceConfig = getConstantServicesByIdApp(state)[app.id] || {}

  const appAccounts = getAppAccountsValidForAppRenewal(serviceConfig, allAppAccounts)
  return {
    idOrg,
    appAccounts: appAccounts ?? []

  }
}
export default connect(mapStateToProps)(View)
