import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getIdOrg, getSupportedFeatures as getSupportedFeaturesSelector } from '@selectors/org'
import { getSupportedFeatures, updateFeatureUsabilityStatus } from '@actions/'
import * as Style from './style'
import Analytics from '@helpers/analytics'
import { FEATURE_FLAGS, SCOPES } from '@root/constants'
import TermsAndConditionsPopup from '@components/popups/termsAndConditionsPopup'
import { H4, Body2, Link, Spacer, ExpandableSection, TextBadge, Stack } from '@toriihq/design-system'
import ToriiToggle from '@components/toriiToggle'
import EnableFor from '../enableFor'
import { LabFeature } from '@components/labsFeatures/types'
import LabsEmptyState from '@components/labsFeatures/labsEmptyState'
import { EmptyStateContainer } from './style'
import customizableDashboardsPreview from './images/customizableDashboardsPreview.svg'

const LabsFeatures = () => {
  const dispatch = useDispatch()
  const idOrg = useSelector(getIdOrg)
  const supportedFeatures = useSelector(getSupportedFeaturesSelector)
  const termsAndConditionsText = 'The features and functionalities provided in Torii Labs are beta services provided for evaluation purposes only and are not considered "Services" pursuant to the service agreement between Customer and Torii. Beta services are provided "as is" with no express or implied warranty, and Torii disclaims any and all liability arising out of or relating to Customer\'s user of any beta services. Torii may discontinue any beta service at any time in Torii\'s sole discretion and may never make them generally available. By enabling any beta features in Torii Labs, Customer agrees to these terms.'

  useEffect(() => {
    if (idOrg) {
      dispatch(getSupportedFeatures({ idOrg }))
    }
  }, [idOrg, dispatch])

  const [showTermsAndConditions, setTermsAndConditionsVisibility] = useState(false)

  const labsFeatures: LabFeature[] = [
    {
      id: FEATURE_FLAGS.CUSTOMIZABLE_DASHBOARDS,
      header: 'Customizable Dashboards',
      imgUrl: customizableDashboardsPreview,
      description: 'View and personalize critical metrics in the new Torii dashboards',
      allowedScopes: [SCOPES.DASHBOARDS_WRITE]
    }
  ]

  const reportToggleCollapse = (isOpen, collapseBoxName) => {
    Analytics.track(`${!isOpen ? 'Open' : 'Close'} labs features`, {
      'Feature Name': collapseBoxName
    })
  }

  const showEmptyState = !labsFeatures.length

  return (
    <Style.Wrapper>
      <Stack direction={'column'} gap={'space-300'} >
        <H4>Labs</H4>
        {showEmptyState
          ? <EmptyStateContainer>
            <LabsEmptyState />
          </EmptyStateContainer> : <>
            <Body2>
              Try out powerful new beta features.
              <br />
              By enabling any beta features in Torii Labs, Customer agrees to the  <Link onClick={() => setTermsAndConditionsVisibility(!showTermsAndConditions)}>Terms and conditions</Link>.
            </Body2>
            {labsFeatures.map(labFeature => {
              return (
                <Style.ExpandableSectionWrapper>
                  <ExpandableSection
                    key={labFeature.id}
                    title={labFeature.header}
                    onToggle={(isOpen) => reportToggleCollapse(isOpen, labFeature.header)}
                    rightSlot={<TextBadge size='Small' color='blue'>{supportedFeatures[labFeature.id] ? 'On' : 'Off'}</TextBadge>}
                  >
                    <>
                      <Style.ToggleRow>
                        <Style.FeatureDescription>{labFeature.description}</Style.FeatureDescription>
                        <Spacer right='space-100'>Enable feature</Spacer>
                        <EnableFor scopes={labFeature.allowedScopes || [SCOPES.SETTINGS_WRITE]}>
                          <ToriiToggle
                            id={labFeature.id}
                            name={labFeature.id}
                            data-testid={labFeature.id}
                            checked={Boolean(supportedFeatures[labFeature.id])}
                            onToggle={() => {
                              Analytics.track('Toggle labs feature', { 'Feature name': labFeature.id, 'Is enabled': !supportedFeatures[labFeature.id] })
                              dispatch(updateFeatureUsabilityStatus({ idOrg, feature: labFeature.id, isEnabled: !supportedFeatures[labFeature.id] }))
                            }}
                          />
                        </EnableFor>
                      </Style.ToggleRow>
                      {labFeature.imgUrl && <Style.Image src={labFeature.imgUrl} alt='View edit history preview' />}
                    </>
                  </ExpandableSection>
                </Style.ExpandableSectionWrapper>
              )
            })}
            <TermsAndConditionsPopup
              isOpen={showTermsAndConditions}
              onCloseAction={() => setTermsAndConditionsVisibility(false)}
              text={termsAndConditionsText}
            />
          </>}
      </Stack>
    </Style.Wrapper>)
}

export default LabsFeatures
