import { formFieldTypes } from '@root/constants'
import { DROPDOWN_OPTIONS_TYPE, WidgetConfig } from '../types'
import { ops } from '@lenses/filters'
import { DATE_FIELD_TYPES, widgetInfoConfig } from '@components/dashboard/widgets/shared'

const config: WidgetConfig = {
  editableSections: [
    widgetInfoConfig,
    {
      key: 'dataConfig',
      label: 'Data',
      fields: [
        {
          key: 'groupBy',
          label: 'Group by',
          type: formFieldTypes.dropdown,
          dropdownOptionsType: DROPDOWN_OPTIONS_TYPE.GROUP_BY
        },
        {
          key: 'datePeriod',
          label: 'Period Selector',
          type: formFieldTypes.dropdown,
          options: [
            { label: 'Weekly', value: 'weekly' },
            { label: 'Monthly', value: 'monthly' },
            { label: 'Quarterly', value: 'quarterly' },
            { label: 'Yearly', value: 'yearly' }
          ],
          showOnFieldSpecificCondition: { fieldKey: 'groupBy', comparisonType: 'type', value: DATE_FIELD_TYPES, op: ops.anyOf }
        },
        {
          key: 'metricFunc',
          label: 'Aggregation',
          type: formFieldTypes.dropdown,
          options: [
            { label: 'Count', value: 'total' },
            { label: 'Summation', value: 'sum' }
          ]
        },
        {
          key: 'metricField',
          label: 'Field',
          type: formFieldTypes.dropdown,
          dropdownOptionsType: DROPDOWN_OPTIONS_TYPE.NUMERIC,
          showOnFieldSpecificCondition: { fieldKey: 'metricFunc', comparisonType: 'value', value: 'total', op: ops.notEquals }
        },
        { key: 'filters', label: 'Filter by', type: formFieldTypes.filters }
      ]
    }
  ]
}

export default config
