import React, { useState } from 'react'
import { Body2, Link, Popover, Spinner, SpinnerColor, Stack, Subtitle2 } from '@toriihq/design-system'
import { getFormattedDate } from '@lenses/utils'
import { useDispatch } from 'react-redux'
import { getAppUserLastVisitTimes } from '@actions/appUsers'
import { IdApp, IdOrg, IdUser } from '@store/types'
import SourceIcon from '@components/sourceIcon'
import { SUPPORT_ARTICLES } from '@root/articles'
import Analytics from '@helpers/analytics'

type Props = {
  lastVisitTime: Date | null
  idOrg: IdOrg
  idApp: IdApp
  idUser: IdUser
  appName: string
}
const LastVisitTimePopover = ({ lastVisitTime, idOrg, idApp, idUser, appName }: Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [lastVisitTimes, setLastVisitTimes] = useState<Array<{ source: string, lastVisitTime: Date }>>([])
  const dispatch = useDispatch()

  const onLinkClick = (e: React.MouseEvent) => {
    e.preventDefault()

    Analytics.track('[Click on] [Last Used Date Popup Learn More Link]', {
      'App Name': appName
    })
    window.open(SUPPORT_ARTICLES.APP_USER_LAST_VISIT_TIMES, '_blank')
  }
  const getContent = () => {
    if (loading) {
      return <Stack gap='space-0' alignItems='center'><Spinner color={SpinnerColor.active} /></Stack>
    }

    return <Stack gap='space-200'>
      <Subtitle2>Last used date by source</Subtitle2>
      <Stack gap='space-100'>
        {(lastVisitTimes.length && lastVisitTimes.some(row => row.lastVisitTime)) ? lastVisitTimes.map((item: any) => {
          return <Stack key={item.source} direction='row' gap='space-050' alignItems='center'>
            <SourceIcon sourceType={item.source} />
            <Body2>
              {getFormattedDate({ date: item.lastVisitTime })}
            </Body2>
          </Stack>
        }) : <Body2>No usage data from any source <br />discovered since Jan 1st, 2024</Body2>}
      </Stack>
      <Link onClick={onLinkClick}>Learn more about usage</Link>
    </Stack>
  }

  const onMouseOver = async ({ isOpen }: { isOpen?: boolean }) => {
    if (!isOpen || lastVisitTimes.length) {
      return
    }

    setLoading(true)
    const response = await dispatch(getAppUserLastVisitTimes({ idOrg, idApp, idUser }))
    setLastVisitTimes(response.lastVisitTimes)
    setLoading(false)

    Analytics.track('[Open] [Last Used Date Popup]', {
      'App Name': appName
    })
  }

  if (!lastVisitTime) {
    return getFormattedDate({ date: lastVisitTime })
  }

  return <Popover content={getContent()} showArrow align='center' onToggle={onMouseOver}>
    {getFormattedDate({ date: lastVisitTime })}
  </Popover>
}

export default LastVisitTimePopover
