import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Table from '@components/table'
import {
  Stack
} from '@toriihq/design-system'
import {
  TABLES,
  ITEMS_PER_PAGE,
  fieldTypes, EMPTY_OBJECT, EMPTY_ARRAY
} from 'src/constants'
import { IMPORT_APP_USERS_STATUS } from '@reducers/appUsers/types'
import colors from '@shared/style/colors'
import UsersEmptyState from './usersEmptyState'
import { debounce } from 'lodash'
import { getColumns } from './columns'
import { useDispatch, useSelector } from 'react-redux'
import { getAppUsers, getAppUsersFieldValues, getUserDetailsFields } from '@actions/'
import { APP_USER_STATUS } from '@shared/types'
import pluralize from 'pluralize/pluralize'
import { deprecatedGetAppsByIds, isAppsLoading } from '@selectors/apps'
import { isLoadingLicensesTypes } from '@selectors/licenses'
import {
  getAllAppUsersState,
  getAppUsersES,
  getFieldValues,
  getImportAppUsersStatus,
  isLoadingAppUsersES
} from '@selectors/appUsers'
import { getUserPreferences } from '@selectors/ui'
import { getUserTablesInfo } from '@selectors/tables'
import { getAppAccountUserConfigurableColumnsOptions } from '@selectors/userDetails'
import { AppAccountUsersProps } from '@pages/renewalForecast/appAccountUsers/types'

const getNoDataProps = () => ({
  className: 'rt-noData',
  style: {
    pointerEvents: 'all',
    top: '40%'
  }
})

const AppAccountUsers = (props: AppAccountUsersProps) => {
  const {
    idApp,
    idAppAccount,
    idOrg,
    isLoadingLicenses,
    setTotalUsers
  } = props
  const dispatch = useDispatch()

  const getAllUsersWereDeletedText = (app, users) => {
    const { name, activeUsersCount } = app

    if (activeUsersCount > 0) {
      return null
    }

    const deletedUserNames = users
      .filter(user => user.status === APP_USER_STATUS.DELETED)
      .map(user => [user.firstName, user.lastName].join(' ').trim())

    if (deletedUserNames.length > 0) {
      const numberOfUsersToShow = 3
      const usersToShow = deletedUserNames.slice(0, numberOfUsersToShow).join(', ')
      const notShownUsers = (deletedUserNames.length - numberOfUsersToShow)
      const suffix = (deletedUserNames.length > numberOfUsersToShow) ? `and ${pluralize('other', notShownUsers, true)}` : ''
      return <div>{name} currently have no users.<br /> {name} had {pluralize('user', deletedUserNames.length, true)} in the past: {usersToShow} {suffix}</div>
    }
  }

  const app = useSelector(deprecatedGetAppsByIds)[idApp] || EMPTY_OBJECT
  const allAppUsers = useSelector(getAppUsersES)
  const users = allAppUsers[idApp] || EMPTY_ARRAY
  const loadingApps = useSelector(isAppsLoading)
  const loadingLicenses = useSelector(isLoadingLicensesTypes)
  const loadingAppUsers = useSelector(isLoadingAppUsersES)

  const allAppUsersState = useSelector(getAllAppUsersState)
  const appState = allAppUsersState[idApp] || EMPTY_OBJECT

  const { loadingMore, totalUsers } = appState

  const filterValues = useSelector(getFieldValues)[idApp]

  const deletedUsersMessage = getAllUsersWereDeletedText(app, users)
  const usersEmptyState = <UsersEmptyState idApp={idApp} />
  const renderNoUsers = deletedUsersMessage || usersEmptyState

  const userPreferences = useSelector(getUserPreferences)
  const userTablePreferences = userPreferences[TABLES.appAccountUsersTable.key] || EMPTY_OBJECT
  const { defaultSort = EMPTY_ARRAY } = userTablePreferences
  const tableInfo = useSelector(getUserTablesInfo)[TABLES.appAccountUsersTable.key]
  const configurableColumnsOptions = useSelector(getAppAccountUserConfigurableColumnsOptions)

  const importAppUsersStatus = useSelector(getImportAppUsersStatus)

  const isLoading = ((loadingApps || loadingLicenses) && (users.length === 0)) || (!loadingMore && loadingAppUsers) || isLoadingLicenses
  const appName = app.name
  const [query, setQuery] = useState('')

  useEffect(() => {
    setTotalUsers && setTotalUsers(totalUsers)
  }, [setTotalUsers, totalUsers])

  const fetchFields = useCallback(() => {
    dispatch(getUserDetailsFields({ idOrg }))
  }, [dispatch, idOrg])

  const getAppUsersData = useCallback(({ limit = ITEMS_PER_PAGE, offset = 0, sort = defaultSort, q = query, reset = false, filters = tableInfo.filters || [] }) => {
    if (reset) {
      fetchFields()
    }
    dispatch(getAppUsers({ idOrg, idApp, limit, offset, sort: sortStringFromArray(sort), q, reset, filters: filters.filter(Boolean), idAppAccount, status: APP_USER_STATUS.ACTIVE }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSort, dispatch, fetchFields, idApp, idAppAccount, idOrg, query, tableInfo.filters, userTablePreferences])

  const sortStringFromArray = sortArray => {
    return sortArray.map(s => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(',')
  }

  const fetchMoreData = (reset = false) => {
    fetchUsersData(reset, reset ? 0 : users.length)
  }

  const fetchUsersData = useCallback((reset = false, offset = 0) => debounce(() => {
    getAppUsersData({ offset: offset, reset })
    dispatch(getAppUsersFieldValues({ idOrg, idApp, fields: ['role', 'externalStatus', 'licenses.name', 'licenses.source', 'appAccountName', 'idAppAccount'] }))
  }, 500, { leading: true, trailing: false })(), [getAppUsersData, dispatch, idOrg, idApp])

  const onSortedChange = (sort) => {
    getAppUsersData({ sort, reset: true })
  }

  const onFilterChange = debounce((filters) => {
    getAppUsersData({ filters, reset: true })
  }, 250)

  const fetchFieldValues = useCallback((field) => {
    const options = getFilterOptions(tableInfo)
    const selectedFilterOption = options.find(f => f.value === field) || {}
    const isFieldSupportValues = [fieldTypes.name, fieldTypes.text, fieldTypes.dropdownMulti, fieldTypes.user].includes(selectedFilterOption.type)
    if (isFieldSupportValues) {
      dispatch(getAppUsersFieldValues({ idOrg, idApp, fields: [field] }))
    }
  }, [dispatch, idApp, idOrg, tableInfo])

  const onSearch = debounce((q) => {
    setQuery(q)
    getAppUsersData({ reset: true, q })
  }, 250)

  const getFilterOptions = (tableInfo) => {
    const filterOptions = tableInfo.filtersOptions
    return filterOptions.concat({
      label: 'User type',
      value: 'userType',
      type: fieldTypes.text
    })
  }

  useEffect(() => {
    if (!isLoadingLicenses) {
      fetchUsersData(true)
    }
  }, [fetchUsersData, isLoadingLicenses])

  useEffect(() => {
    dispatch(getAppUsersFieldValues({ idOrg, idApp, fields: ['role', 'externalStatus', 'licenses.name', 'licenses.source', 'appAccountName', 'idAppAccount'] }))
  }, [users, dispatch, idOrg, idApp])

  const columns = useMemo(() => {
    return getColumns({ tableInfo, appName })
  }, [appName, tableInfo])

  useEffect(() => {
    if (importAppUsersStatus === IMPORT_APP_USERS_STATUS.SYNC_PRESUMABLY_ENDED) {
      getAppUsersData({ reset: true })
      dispatch(getAppUsersFieldValues({ idOrg, idApp, fields: ['licenses.name', 'licenses.source'] }))
    }
  }, [importAppUsersStatus, idOrg, idApp, getAppUsersData, dispatch])

  return (
    <Stack direction='column' gap='space-400'>
      <Table
        getNoDataProps={getNoDataProps}
        getTdProps={setTdStyle}
        tableKey={TABLES.appAccountUsersTable.key}
        data={users}
        columns={columns}
        emptyStateMessage={renderNoUsers}
        loading={isLoading}
        loadingMore={loadingMore}
        searchable
        manual
        filterable
        configurableColumnsOptions={configurableColumnsOptions}
        configurableColumns
        filtersOptions={getFilterOptions(tableInfo)}
        fetchData={fetchMoreData}
        forceShowSearch
        onSearch={onSearch}
        onSortedChangeCB={onSortedChange}
        onFilterChange={onFilterChange}
        filterOptionsValuesPerKey={filterValues}
        fetchFieldValues={fetchFieldValues}
        totalCount={totalUsers}
        appName={appName}
      />
    </Stack>
  )
}

const setTdStyle = () => ({
  style: {
    border: 0,
    padding: '3px 10px',
    color: colors.black
  }
})

export default AppAccountUsers
