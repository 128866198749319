import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, ButtonType, ButtonSize } from '@toriihq/design-system'
import {
  getAppsFieldValues,
  getAppsV2,
  getAppsV2Amount,
  setAppsDetails,
  toggleAddApplication,
  toggleShareReportPopup
} from '@actions/'
import PropTypes from 'prop-types'
import {
  getAppsV2 as getAppsV2Selector,
  getAppsV2ConfigurableColumnsOptions,
  getEditableAppDetailsFieldsV2,
  getFieldValues,
  isAppsV2Loading
} from '@selectors/apps'
import Table from '@components/table'
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  fieldTypes,
  formFieldTypes,
  ITEMS_PER_PAGE,
  SCOPES,
  TABLES
} from '@root/constants'
import { getAppsV2TablesInfo } from '@selectors/tables'
import getColumns from './columns'
import emptyImage from '@media/applications.svg'
import texts from '@shared/style/texts'
import { css } from 'glamor'
import Analytics from '@helpers/analytics'
import debounce from 'lodash/debounce'
import { getUserPreferences } from '@selectors/ui'
import config from '@root/config'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { getValidFilters, removeColumnOptionsByFilterOptions } from '@shared/filters'
import AccessControl from '@lenses/accessControl'
import { getIsOnlyAppOwner } from '@selectors/me'
import { REPORT_KEY } from '@actions/reports/reports.types'

const CSS = {
  noApps: css({
    textAlign: 'center',
    ' > *:not(:last-child)': {
      marginBottom: '17px'
    }
  })
}

export const updateAppsOwnerFiltersAndBulkEditOptions = (filterOptions, bulkEditOptions) => {
  const appOwnerRedundantFilter = ['primaryOwner']
  const appOwnerRestrictedBulkEditFields = ['state', 'primaryOwner']

  filterOptions = filterOptions.filter(filterOption => !appOwnerRedundantFilter.includes(filterOption.value))
  bulkEditOptions = bulkEditOptions.filter(editableAppField => !appOwnerRestrictedBulkEditFields.includes(editableAppField.systemKey || editableAppField.idField))

  return [filterOptions, bulkEditOptions]
}

const AppsV2 = ({ idOrg }) => {
  const dispatch = useDispatch()

  const { apps, total, loadingMore } = useSelector(getAppsV2Selector)
  const loading = useSelector(isAppsV2Loading)
  const { key, filtersOptions, columnsConfiguration, dynamicColumnsInfo, preDefinedColumnsMapping, stateInfo, filters: tableFilters } = useSelector(getAppsV2TablesInfo)
  const validFilters = getValidFilters(tableFilters || [])
  const filterValues = useSelector(getFieldValues)
  let editableAppDetailsFields = useSelector(getEditableAppDetailsFieldsV2)
  let appsFiltersOptions = [...filtersOptions]

  const isOnlyAppOwner = useSelector(getIsOnlyAppOwner)
  if (isOnlyAppOwner) {
    [appsFiltersOptions, editableAppDetailsFields] = updateAppsOwnerFiltersAndBulkEditOptions(appsFiltersOptions, editableAppDetailsFields)
  }

  const configurableColumnsOptions = removeColumnOptionsByFilterOptions(appsFiltersOptions, useSelector(getAppsV2ConfigurableColumnsOptions))
  const { defaultSort = EMPTY_ARRAY, columnsConfiguration: columnsOrder } = useSelector(getUserPreferences)[TABLES.appsV2Table.key] || EMPTY_OBJECT
  const [selectedApps, setSelectedApps] = useState([])
  const [columns, setColumns] = useState([])
  const [query, setQuery] = useState('')

  const hasExpensesAccess = AccessControl.useIsAccessible({ scopes: [SCOPES.EXPENSE_READ, SCOPES.APP_OWNER_READ] })
  const hasContractsAccess = AccessControl.useIsAccessible({ scopes: [SCOPES.CONTRACTS_READ, SCOPES.APP_OWNER_READ] })
  const hasLicenseCostAndChargebackAccess = AccessControl.useIsAccessible({ scopes: [SCOPES.LICENSE_AND_CHARGEBACK_READ, SCOPES.APP_OWNER_READ] })
  const isMultiSelectAllowed = AccessControl.useIsAccessible({ scopes: [SCOPES.APPLICATIONS_WRITE, SCOPES.APP_OWNER_WRITE] })

  const history = useHistory()

  const sortStringFromArray = sortArray => {
    return sortArray.map(s => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(',')
  }

  const getApps = useCallback(async ({ limit = ITEMS_PER_PAGE, offset = 0, sort = defaultSort, q = query, reset = false, filters = tableFilters }) => {
    await dispatch(getAppsV2({ idOrg, limit, offset, sort: sortStringFromArray(sort), q, reset, filters }))
  }, [defaultSort, dispatch, idOrg, query, tableFilters])

  useDeepCompareEffect(() => {
    if (!idOrg || loading) {
      return
    }
    getApps({ reset: true })
    dispatch(getAppsV2Amount({ idOrg }))
  }, [idOrg, defaultSort, validFilters])

  useEffect(() => {
    const assignUserCB = () => {
      getApps({ reset: true, limit: apps.length })
    }

    setColumns(getColumns({
      columnsConfiguration, dynamicColumnsInfo, preDefinedColumnsMapping, stateInfo, assignUserCB, columnsRestrictions: { hasExpensesAccess, hasContractsAccess, hasLicenseCostAndChargebackAccess }, isOnlyAppOwner, idOrg }))
  }, [columnsConfiguration, dynamicColumnsInfo, preDefinedColumnsMapping, isOnlyAppOwner, stateInfo, getApps, apps.length, hasExpensesAccess, hasContractsAccess, hasLicenseCostAndChargebackAccess, idOrg])

  const fetchAppsData = debounce((reset = false) => {
    getApps({ offset: reset ? 0 : apps.length, reset })
  }, 500, { leading: true, trailing: false })

  const onSearch = debounce((q) => {
    setQuery(q)
    getApps({ reset: true, q })
  }, 500)

  const onBulkSubmit = async (data, selectedApps) => {
    const idField = Object.keys(data)[0]
    const field = editableAppDetailsFields.find(field => field.idField.toString() === idField)

    let value = data[idField]
    const isDelete = !value
    if (!isDelete && field.type === formFieldTypes.usersDropdown) {
      value = value.id
    }
    if (!isDelete && field.type === formFieldTypes.usersDropdownMulti) {
      value = value.map(v => v.id)
    }

    await dispatch(setAppsDetails({ idOrg, idApps: selectedApps, fieldsValues: [{ idField, values: [].concat(value) }], isBulk: true }))
    await getApps({ reset: true, limit: apps.length })
  }

  const onSelectChange = selection => setSelectedApps(selection)

  const onConnectClick = () => {
    history.push(`/team/${idOrg}/services/all`)

    Analytics.track('Click to go to services', {
      from: 'Empty state applications list'
    })
  }

  const renderNoApps = () => {
    return (
      <div {...CSS.noApps}>
        <img alt='no apps' src={emptyImage} />
        <div {...css(texts.heading)}>Discover your apps</div>
        <Button type={ButtonType.secondary} onClick={onConnectClick} label='Connect service providers' />
      </div>
    )
  }

  const openAddApplicationPopup = () => {
    dispatch(toggleAddApplication({ isAddApplicationOpen: true }))
  }

  const customButton = {
    button: <Button dataTestId='add-application-button' onClick={openAddApplicationPopup} size={ButtonSize.small} icon='Plus' type={ButtonType.secondary} />,
    tooltipMessage: 'Add application',
    scopes: [SCOPES.APPLICATIONS_WRITE]
  }

  const fetchFieldValues = (field) => {
    const selectedFilterOption = appsFiltersOptions.find(f => f.value === field) || {}
    const isFieldSupportValues = [fieldTypes.text, fieldTypes.user, fieldTypes.dropdown, fieldTypes.dropdownMulti, fieldTypes.userMulti].includes(selectedFilterOption.type)
    if (isFieldSupportValues) {
      dispatch(getAppsFieldValues({ idOrg, fields: [field] }))
    }
  }

  const prepareExportParams = ({ sort, query, filters }) => {
    const columnsOrdered = [...columns].sort((a, b) => columnsOrder.indexOf(a.id || a.accessor) - columnsOrder.indexOf(b.id || b.accessor))

    return {
      sort: sort.map(s => `${s.id}:${s.desc ? 'desc' : 'asc'}`),
      q: query,
      fields: columnsOrdered.filter(col => ((col.show === undefined || col.show) && !col.hideFromCSV)).map(col => col.id || col.accessor),
      filters
    }
  }

  const onShareReport = ({ sort: rawSort, query: rawQuery, filters: rawFilters }) => {
    const { sort, q, fields, filters } = prepareExportParams({ sort: rawSort, query: rawQuery, filters: rawFilters })

    dispatch(toggleShareReportPopup({ isOpen: true, pageName: 'Applications - Review', report: { config: { sort, q, fields, filters }, reportName: 'Applications - Review', reportKey: REPORT_KEY.APPS } }))
  }

  const exportToCsv = ({ sort: rawSort, query: rawQuery, filters: rawFilters }) => {
    const { sort, q, fields, filters } = prepareExportParams({ sort: rawSort, query: rawQuery, filters: rawFilters })

    const sortParam = `sort=${sort.join(',')}`
    const queryParam = `q=${q}`
    const fieldsParam = `fields=${fields.join(',')}`
    const filtersParam = `filters=${encodeURIComponent(JSON.stringify(filters))}`

    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/apps/csv?${sortParam}&${queryParam}&${filtersParam}&${fieldsParam}`
    const newWindow = window.open(url, '_blank')
    newWindow.opener = null
  }

  return (
    <Table
      tableKey={key}
      emptyStateMessage={renderNoApps()}
      data={apps}
      columns={columns}
      loading={loading}
      loadingMore={loadingMore}
      exportable
      exportFunction={exportToCsv}
      onShareReport={onShareReport}
      searchable
      selectable={isMultiSelectAllowed}
      selectedItems={selectedApps}
      onSelectChange={onSelectChange}
      filterable
      filtersOptions={appsFiltersOptions}
      configurableColumns
      configurableColumnsOptions={configurableColumnsOptions}
      manual
      supportViews
      supportBulkEdit
      bulkEditFields={editableAppDetailsFields}
      onBulkSubmit={onBulkSubmit}
      bulkEditFieldKey='idField'
      bulkEditFieldName='idField'
      itemsName='app'
      customButton={isOnlyAppOwner ? null : customButton}
      allowedScopes={[SCOPES.APPLICATIONS_WRITE, SCOPES.APP_OWNER_WRITE]}
      fetchData={fetchAppsData}
      forceShowSearch
      onSearch={onSearch}
      filterOptionsValuesPerKey={filterValues}
      fetchFieldValues={fetchFieldValues}
      totalCount={total}
    />
  )
}

AppsV2.propTypes = {
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  style: PropTypes.object
}

export default AppsV2
