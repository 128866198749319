import React, { ReactElement } from 'react'
import { useSelectedWorkflow } from '@pages/workflow_v2/hooks/useSelectedWorkflow'
import { AlertBox, AlertBoxType, Link } from '@toriihq/design-system'
import { SUPPORT_ARTICLES } from '@root/articles'
import { ThrottledWorkflowsLearnMore } from './styles'
import { analytics } from '@shared/services/workflows/analytics'
import { togglePendingExecutionsPopup } from '@store/actions'
import { useDispatch } from 'react-redux'

const ALERT_BOX_TEXT = 'Due to a spike in the number of triggers this workflow has been throttled. To ensure stability and prevent unintended actions, its execution has been temporarily limited. Review the pending executions and confirm if you’d like to resume processing.'

export const ThrottledInfo = (): ReactElement => {
  const dispatch = useDispatch()

  const workflow = useSelectedWorkflow()

  const reviewExecutionOnClick = (idWorkflow: number) => {
    dispatch(togglePendingExecutionsPopup({ isOpen: true, idWorkflow: workflow.id, workflowName: workflow.name }))
    analytics.throttledWorkflow.reviewExecutions(workflow.id, workflow.name)
  }

  return workflow.isThrottled ? <AlertBox
    type={AlertBoxType.NOTICE}
    secondaryButton={{ label: 'Review Executions', onClick: reviewExecutionOnClick }}
    title='Throttled workflow'
    description={<>
      <span>{ALERT_BOX_TEXT}</span>
      <ThrottledWorkflowsLearnMore>
        <Link
          href={SUPPORT_ARTICLES.WORKFLOWS_THROTTLED}
          target='_blank'
          onClick={() => analytics.throttledWorkflow.learnMore('Workflow')}
        >
          Learn more
        </Link>
      </ThrottledWorkflowsLearnMore>
    </>
    }
  /> : <></>
}
