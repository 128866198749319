import Analytics from '../../helpers/analytics'
import Alert, { ALERT_TYPE } from '../alert/index'
import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import TextControl from '../form/text-control'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import { fontSize, fontWeight } from '../../shared/style/sizes'
import texts from '../../shared/style/texts'
import config from '../../config'
import Recaptcha from 'react-google-recaptcha'
import { safeRedirect } from '@shared/utils'
import { Field, Form } from 'react-final-form'
import SubmitButton from '@components/submitButton'
import { Spacer, Button, ButtonType, ButtonSize, Link } from '@toriihq/design-system'
import { base64Encode, base64Decode } from '@helpers/base64'

const CSS = {
  smallText: css({
    fontSize: fontSize.small,
    color: `${colors.grey1} !important`,
    lineHeight: '22px'
  }),
  link: css({
    fontWeight: `${fontWeight.normal} !important`,
    textDecoration: 'underline'
  }),
  header: css(texts.heading, texts.headers.large, {
    textAlign: 'center',
    marginBottom: '40px'
  }),
  loginDescription: css(texts.heading, {
    marginTop: '20px',
    textAlign: 'center'
  }),
  linksAndButtonsContainer: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }),
  linkTitle: css({
    marginBottom: '20px',
    lineHeight: '30px',
    fontWeight: fontWeight.normal,
    fontSize: fontSize.large
  })
}

class Login extends React.Component {
  static REMEMBER_ME_LOCAL_STORAGE_KEY = 'remember_me'

  state = {
    prefersPassword: false,
    prefersLink: false,
    rememberMeEmailAddress: undefined
  }

  emailSetupInput = {}
  passwordSetupInput = {}

  setPrefersPassword = async () => {
    this.setState({ prefersPassword: true, prefersLink: false })
  }

  setPrefersLink = async () => {
    this.setState({ prefersPassword: false, prefersLink: true })
  }

  async componentDidMount () {
    try {
      const rememberMeEmailAddress = localStorage.getItem(Login.REMEMBER_ME_LOCAL_STORAGE_KEY)
      if (rememberMeEmailAddress) {
        this.setState({
          rememberMeEmailAddress: base64Decode(rememberMeEmailAddress)
        })
      }

      const { getIsLoggedIn, getMe } = this.props
      const { isLoggedIn } = await getIsLoggedIn()
      if (isLoggedIn) {
        const me = await getMe()
        Analytics.identify(me)
      }
      if (this.emailSetupInput.email) {
        this.emailSetupInput.email.focus()
      } else if (this.passwordSetupInput.password) {
        this.passwordSetupInput.password.focus()
      }
    } catch (e) {
      return e
    }
  }

  componentDidUpdate (prevProps) {
    const { onLoginSuccess } = prevProps
    const { authState, logoutState } = this.props
    if (authState === 'AUTHENTICATED' && (logoutState.success || !logoutState.error)) {
      onLoginSuccess()
    }
  }

  onInstantLogin = () => {
    const { loginState, instantAccessLogin, next } = this.props

    return instantAccessLogin(loginState.SAMLCheck.email, next)
  }

  handleEmailSubmit = () => {
    const { isLoginUsingSAML, loginState: { SAMLCheck }, logoutState, resetLogout } = this.props
    logoutState.success && resetLogout()

    const email = this.emailSetupInput.email.value()

    return isLoginUsingSAML({
      email,
      captcha: SAMLCheck.data.captchaNeeded ? this.emailSetupInput.captcha.getValue() : undefined
    })
      .then((response) => {
        localStorage.setItem(Login.REMEMBER_ME_LOCAL_STORAGE_KEY, base64Encode(email))

        if (response && response.loginUrl) {
          if (response.allowLoginsViaEmailAndPassword) {
            setTimeout(() => {
              const { prefersPassword } = this.state
              if (!prefersPassword) {
                safeRedirect(response.loginUrl)
              }
            }, 3 * 1000)
          } else {
            safeRedirect(response.loginUrl)
          }
        }
      })
      .catch((e) => {
        SAMLCheck.data.captchaNeeded && this.emailSetupInput.captcha && this.emailSetupInput.captcha.reset()
        throw e
      })
  }

  handlePasswordSubmit = () => {
    const { login, loginState, onLoginSuccess } = this.props
    const { data, SAMLCheck } = loginState

    return login({
      email: SAMLCheck.email,
      password: this.passwordSetupInput.password.value(),
      captcha: data.captchaNeeded ? this.passwordSetupInput.captcha.getValue() : undefined
    }).then(onLoginSuccess)
      .catch((e) => {
        data.captchaNeeded && this.passwordSetupInput.captcha && this.passwordSetupInput.captcha.reset()
        throw e
      })
  }

  renderEmailField = props => {
    const { meta, input, ...rest } = props

    return <TextControl
      ref={(c) => (this.emailSetupInput.email = c)}
      iconName='User'
      hasError={meta.touched && Boolean(meta.error)}
      error={meta.error}
      {...input}
      {...rest}
    />
  }

  renderEmailStep = (alerts, allowLoginsViaEmailAndPassword) => {
    const { isFromCatalog, loginState } = this.props
    const { loading, SAMLCheck } = loginState
    const { rememberMeEmailAddress } = this.state
    const initialValues = { email: rememberMeEmailAddress }

    return <Form
      key={'emailForm'}
      onSubmit={this.handleEmailSubmit}
      initialValues={initialValues}
      render={(formProps) => {
        const { handleSubmit, hasValidationErrors } = formProps
        return <form onSubmit={handleSubmit}>
          <fieldset>
            <Field
              disabled={loading}
              name='email'
              placeholder='Email'
              component={this.renderEmailField}
              validate={emailValidation}
            />
            {allowLoginsViaEmailAndPassword && <div {...css(CSS.smallText, { marginTop: '-15px' })} >Redirecting...(<Button type={ButtonType.compact} size={ButtonSize.small} onClick={this.setPrefersPassword} label='or click here to login using a password' />)</div>}
            <div {...CSS.linksAndButtonsContainer}>
              {SAMLCheck.data.captchaNeeded && <Recaptcha
                name='samlcheck-captcha'
                ref={(c) => (this.emailSetupInput.captcha = c)}
                sitekey={config.recaptcha.sitekey}
              />}
              {!allowLoginsViaEmailAndPassword && <SubmitButton form={{ handleSubmit, valid: !hasValidationErrors }} label='Next' recoveryTime={0} />}
              {alerts}
              {!isFromCatalog && <Spacer top={'space-200'}><Link href={config.catalogBaseUrl}>Application catalog</Link></Spacer>}
            </div>
          </fieldset>
        </form>
      }}
    />
  }

  renderPasswordStep = (alerts, isNotUsingSAML) => {
    const { loginState, location } = this.props
    const { hasError, data, help, loading } = loginState
    const locationDescriptor = {
      search: location.search
    }
    const forgotPasswordLink = <ReactRouterLink {...css(CSS.smallText, CSS.link)} to={{ ...locationDescriptor, pathname: '/login/forgot' }}>Forgot your password?</ReactRouterLink>

    return <Form
      key={'passswordForm'}
      onSubmit={this.handlePasswordSubmit}
      render={(formProps) => {
        const { handleSubmit, hasValidationErrors } = formProps
        return <form onSubmit={handleSubmit}>
          <fieldset>
            <TextControl
              ref={(c) => (this.passwordSetupInput.password = c)}
              name='password'
              placeholder='Password'
              type='password'
              hasError={hasError.password}
              help={help.password}
              disabled={loading}
              iconName='Lock'
            />
            {alerts}
            {data.captchaNeeded && <Recaptcha
              name='captcha'
              ref={(c) => (this.passwordSetupInput.captcha = c)}
              sitekey={config.recaptcha.sitekey}
            />}
            <div {...CSS.linksAndButtonsContainer}>
              <SubmitButton form={{ handleSubmit, valid: !hasValidationErrors }} label='Sign In' recoveryTime={0} />
              <Spacer top={'space-200'}><div> {forgotPasswordLink} </div></Spacer>
              {isNotUsingSAML && <Spacer top={'space-200'}><div {...CSS.smallText} >You can also <Button type={ButtonType.compact} size={ButtonSize.small} onClick={this.setPrefersLink} label='sign in with a magic link' /></div></Spacer>}
            </div>
          </fieldset>
        </form>
      }}
    />
  }

  renderLinkStep = (alerts) => {
    return <>
      <div {...CSS.loginDescription}>
        <div {...CSS.linkTitle}>Get a magic link sent to your email <br /> that'll sign you in instantly</div>
        {alerts}
        <Button onClick={this.onInstantLogin} label='Go' />
      </div>
      <div {...CSS.linksAndButtonsContainer}>
        <Spacer top={'space-200'}><div {...CSS.smallText} >You can also <Button type={ButtonType.compact} size={ButtonSize.small} onClick={this.setPrefersPassword} label='login using password' /></div></Spacer>
      </div>
    </>
  }

  render () {
    const alerts = []
    const { authState, loginState, isEmailSent, isFromCatalog, isExtensionLogin, logoutState } = this.props
    const { prefersLink, prefersPassword } = this.state
    const { success, error, redirect, SAMLCheck } = loginState
    const isAuthenticated = !redirect && (success || (authState === 'AUTHENTICATED' && (logoutState.success || !logoutState.error)))
    const isNotUsingSAML = loginState.SAMLCheck.isSAML === false
    const allowLoginsViaEmailAndPassword = loginState.SAMLCheck.allowLoginsViaEmailAndPassword

    if (isAuthenticated) {
      alerts.push(
        <Alert key='success' type={ALERT_TYPE.SUCCESS}>
          Success. Redirecting...
        </Alert>
      )
    }

    if (redirect) {
      alerts.push(
        <Alert key='success' type={ALERT_TYPE.SUCCESS}>
          Redirecting...
        </Alert>
      )
    }

    if (error) {
      alerts.push(
        <Alert key='error' type={ALERT_TYPE.ERROR}>
          {error}
        </Alert>
      )
    }

    if (SAMLCheck.error) {
      alerts.push(
        <Alert key='SAMLCheck-error' type={ALERT_TYPE.ERROR}>
          {SAMLCheck.error}
        </Alert>
      )
    }

    if (isEmailSent) {
      alerts.push(
        <Alert key='success' type={ALERT_TYPE.SUCCESS}>
          Check your email to complete signing in
        </Alert>
      )
    }

    let content = null
    if (isAuthenticated || redirect || isEmailSent) {
      content = <>{alerts}</>
    } else {
      if (!isNotUsingSAML && (!allowLoginsViaEmailAndPassword || !prefersPassword)) {
        content = this.renderEmailStep(alerts, allowLoginsViaEmailAndPassword)
      } else if ((isFromCatalog || isExtensionLogin || prefersLink) && !prefersPassword) {
        content = this.renderLinkStep(alerts)
      } else {
        content = this.renderPasswordStep(alerts, isNotUsingSAML)
      }
    }

    return (
      <>
        <div {...CSS.header}>Sign in to Torii</div>
        {content}
      </>
    )
  }
}

const emailValidation = value => !value || !/^\S+@\S+\.\S+/.test(value) ? 'Invalid email address' : undefined

export default Login
