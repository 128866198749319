import { AGGREGATION_SORT_ORDERS, AGGREGATION_TYPES, DATE_PERIOD, METRIC_FUNC, Widget } from '@reducers/dashboards/types'
import { DATE_FORMAT, formFieldTypes } from '@root/constants'
import { capitalize } from 'lodash'
import moment from 'moment'
import { DATE_FIELD_TYPES } from './shared'

export const getFormattedData = ({ displayConfig, aggregations }) => {
  const shouldDisplayAggregationValue = aggregations.length > 0 && aggregations[0]?.aggregations !== undefined
  const valueProperty = shouldDisplayAggregationValue ? 'aggregations' : 'value'

  const { datePeriod, field } = displayConfig?.groupBy || {}

  const getLabel = (label) => {
    if (label === 'Other') {
      return label
    }

    if (field?.type === formFieldTypes.bool) {
      if (label === 0) {
        return 'No'
      } else if (label === 1) {
        return 'Yes'
      }
    }

    if (DATE_FIELD_TYPES.includes(field?.type)) {
      if (datePeriod) {
        switch (datePeriod) {
          case DATE_PERIOD.WEEKLY:
            return moment(label).startOf('isoWeek').format(DATE_FORMAT)
          case DATE_PERIOD.MONTHLY:
            return moment(label).format('MMM YY')
          case DATE_PERIOD.QUARTERLY:
            return `Q${moment(label).quarter()} ${moment(label).format('YYYY')}`
          case DATE_PERIOD.YEARLY:
            return moment(label).format('YYYY')
          default:
            return moment(label).format(DATE_FORMAT)
        }
      }

      return moment(label).format(DATE_FORMAT)
    }

    if (field?.systemKey === 'score') {
      if (label === -1) {
        return 'No data'
      }
    }

    return capitalize(label)
  }

  const result = aggregations.map((entry, i) => ({ ...entry, value: entry[valueProperty], name: getLabel(entry.key) }))

  return result.sort((a, b) => {
    if (a.name === 'Other') return 1
    if (b.name === 'Other') return -1

    if (a.name === 'No data') return 1
    if (b.name === 'No data') return -1

    return 0
  })
}

type getAggsForGroupByResponse = { field: string, aggregationType: AGGREGATION_TYPES, options: { order:AGGREGATION_SORT_ORDERS, size: number, datePeriod?: DATE_PERIOD }, aggs?: any }

export const getAggsForGroupBy = ({ widget }: { widget: Widget }) : getAggsForGroupByResponse | undefined => {
  if (!widget.displayConfig.groupBy?.field) {
    return undefined
  }

  const aggregationType = DATE_FIELD_TYPES.includes(widget.displayConfig.groupBy.field.type)
    ? AGGREGATION_TYPES.DATE_HISTOGRAM
    : AGGREGATION_TYPES.GROUP_BY

  const result: getAggsForGroupByResponse = {
    field: widget.displayConfig.groupBy.field.systemKey,
    aggregationType,
    options: {
      order: AGGREGATION_SORT_ORDERS.DESC,
      size: widget.displayConfig.groupBy.size,
      datePeriod: widget.displayConfig.groupBy.datePeriod
    }
  }

  if (widget.displayConfig.groupBy.metric?.func && widget.displayConfig.groupBy.metric?.func !== METRIC_FUNC.TOTAL) {
    result.aggs = {
      field: widget.displayConfig.groupBy.metric?.field?.systemKey,
      aggregationType: AGGREGATION_TYPES.METRIC,
      options: {
        metricFunction: widget.displayConfig.groupBy.metric?.func
      }
    }
  }

  return result
}
