import React, { JSX } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Icon, Tooltip, Counter, generateAttributeId, IconProps } from '@toriihq/design-system'
import { toggleNavigation } from '@actions/'
import RelativeTeamLink from '@components/relativeTeamLink'
import { MenuContainer, NavigationMenuLink, RouteText, RouteIcon, BadgeContainer, RouteLinkContainer, LockIcon } from './styles'
import { getNavigationRoutes } from '@selectors/ui'
import Analytics from '@helpers/analytics'

interface Route {
  path: string
  derivedPaths: string[]
  text: string
  icon: IconProps['name']
  badge?: number
  badgeTooltipText?: string
  isFeatureEnabledInPlan: boolean
  topDivider?: boolean
}

interface NavigationMenuProps {
  isOpen: boolean
  onClick?: () => void
  routes?: Route[]
}

const NavigationMenu = (props: NavigationMenuProps): JSX.Element => {
  const { isOpen } = props
  const routes = useSelector(getNavigationRoutes)
  const dispatch = useDispatch()
  const location = useLocation()

  const isRouteMatch = (route: Route): boolean => {
    return Boolean(location.pathname.match(new RegExp(`team/\\d+/(${route.derivedPaths.join('|')})`)))
  }

  const toggleNavigationDispatcher = () => dispatch(toggleNavigation())

  const routeLinkContent = (route: Route) => {
    const content = <><Icon name={route.icon} color='inherit' size='mobile' />
      {route.badge && (
        <BadgeContainer>
          <Counter size='small' value={route.badge} needsAttention selected notification />
        </BadgeContainer>
      )}</>

    const contentWithTooltip = <Tooltip
      placement='right'
      label={route.badgeTooltipText ? `${route.text}: ${route.badgeTooltipText}` : route.text}>
      {content}
    </Tooltip>

    return (
      <RouteLinkContainer>
        {route.icon && <RouteIcon>
          {isOpen
            ? content
            : contentWithTooltip}
        </RouteIcon>
        }
        {isOpen && (
          <RouteText>
            {route.text}
          </RouteText>
        )}
        { route.isFeatureEnabledInPlan && isRouteMatch(route) && isOpen && <Icon name='ChevronRight' color='inherit' /> }
        { !route.isFeatureEnabledInPlan && isOpen && <LockIcon className='lockIcon' $isSelected={isRouteMatch(route)}><Icon name='Lock' color='inherit' /></LockIcon> }
      </RouteLinkContainer>
    )
  }

  const onRouteLinkClick = (route: Route) => {
    Analytics.track('Navigate to', {
      'Page name': route.text
    })
  }

  const navItem = (route: Route, toggleNavigation: () => void) => (
    <div key={route.path}>
      {route.topDivider && <hr />}
      <div onClick={toggleNavigation} data-intercom-target={generateAttributeId(`intercom-nav-${route.text}`)}>
        <RelativeTeamLink nav to={route.path} onClick={() => onRouteLinkClick(route)}>
          <NavigationMenuLink $isSelected={isRouteMatch(route)}>{routeLinkContent(route)}</NavigationMenuLink>
        </RelativeTeamLink>
      </div>
    </div>
  )

  return (
    <MenuContainer>
      {routes.map((route) =>
        navItem(route, toggleNavigationDispatcher)
      )}
    </MenuContainer>
  )
}

export default React.memo(NavigationMenu)
