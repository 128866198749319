import { RenewalAlertState } from './RenewalAlertState'
import { RenewalForecastButtonFromLocation } from '@components/renewalForecastButton/types'

export const shouldDisplayRenewalForecastButton = (state: RenewalAlertState, renewalForecastButtonFromLocation: RenewalForecastButtonFromLocation): boolean => {
  const allowedStatesForAppOverview = [
    RenewalAlertState.LESS_THAN_120_DAYS_DISABLED_AUTO_RENEWAL,
    RenewalAlertState.LESS_THAN_120_DAYS_NO_CANCELLATION_DEADLINE,
    RenewalAlertState.LESS_THAN_120_DAYS_RENEWAL
  ]

  const allowedStatesForRenewalCalendar = [
    ...allowedStatesForAppOverview,
    RenewalAlertState.MORE_THAN_120_DAYS_DISABLED_AUTO_RENEWAL,
    RenewalAlertState.MORE_THAN_120_DAYS_NO_CANCELLATION_DEADLINE,
    RenewalAlertState.MORE_THAN_120_DAYS_RENEWAL
  ]

  const allowedStates =
        renewalForecastButtonFromLocation === RenewalForecastButtonFromLocation.RenewalCalendar
          ? allowedStatesForRenewalCalendar
          : allowedStatesForAppOverview

  return allowedStates.includes(state)
}
