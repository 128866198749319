export function getAppAccountsByIdApp ({ idApp, accountSyncStatuses }) {
  const appAccounts = (accountSyncStatuses || [])
    .filter(accountSyncStatus => idApp === accountSyncStatus.idApp)
    .map(accountSyncStatus => {
      const { idAppAccount, appAccountName, appAccountType, lastSyncTime, lastUsersAndLicensesFileUploadedBy: lastSyncBy, lastUsersAndLicensesFileLastSyncTime,
        source, licensesManagedManually, workflowsToInvalidate, syncStatus, connectedAt } = accountSyncStatus

      return {
        idAppAccount,
        appAccountName,
        appAccountType,
        lastSyncTime,
        lastSyncBy,
        lastUsersAndLicensesFileLastSyncTime,
        source,
        licensesManagedManually,
        workflowsToInvalidate,
        syncStatus,
        connectedAt
      }
    })
  return appAccounts
}
