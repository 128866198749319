import KeyOpValueVerticalFilter from '@components/filters/keyOpValueVerticalFilter'
import { BranchFilter as BranchFilterType, BRANCH_FILTER_ENTITY } from '@shared/workflows/types'
import UserVerticalFilter from '@components/filters/userVerticalFilter'
import { UserKeyOption } from '@components/filters/userVerticalFilter/types'
import { UserEntityCriterion } from '@shared/filters/userFilters/types'
import VerticalFilterWrapper from '@components/filters/verticalFilterWrapper'
import React, { ReactElement, useCallback, useEffect } from 'react'
import { isNil } from 'lodash'
import Select from '@components/select'
import {
  EntityOption,
  FilterDynamicOptionsDataByIdField,
  KeyOptionsByEntity,
  KeyOptionValueByEntity,
  OnChangeBranchFilterProps
} from '@components/filters/ifElseBranchFilters/types'
import { getValue } from '@root/shared/utils'
import { BRANCH_FILTER_DYNAMIC_ENTITY_PREFIX } from '@shared/workflows/consts'
import { getIdFieldFromDynamicIdentity } from '../utils/getIdFieldFromDynamicIdentity'

interface Props {
  filter: BranchFilterType
  disabled: boolean
  onRemove: (any) => void
  entityOptions?: EntityOption[]
  keyOptionsByEntityType?: KeyOptionsByEntity
  filterDynamicOptionsDataByIdField?: FilterDynamicOptionsDataByIdField
  onChange: ({ key, newValue }: Pick<OnChangeBranchFilterProps, 'key' | 'newValue'>) => void,
  keyOptionValuesByEntityType?: KeyOptionValueByEntity
}

const BranchFilter = ({
  filter: branchFilter,
  disabled,
  onRemove,
  onChange,
  entityOptions = [],
  keyOptionsByEntityType,
  filterDynamicOptionsDataByIdField,
  keyOptionValuesByEntityType
}: Props): ReactElement => {
  const { entity, filter } = branchFilter
  const hasEntityValue = !isNil(entity)
  const shouldRenderEntitySelector = entityOptions.length > 1

  const handleEntityChange = useCallback(
    (entity: EntityOption) => onChange({ key: 'entity', newValue: entity }),
    [onChange]
  )

  useEffect(() => {
    if (!hasEntityValue && entityOptions.length === 1) {
      const [theOnlyEntityOption] = entityOptions
      handleEntityChange(theOnlyEntityOption)
    }
  }, [entityOptions, handleEntityChange, hasEntityValue])

  let filterComponent = <></>

  if (hasEntityValue) {
    const keyOptionValues = keyOptionValuesByEntityType?.[entity!]

    if (entity === BRANCH_FILTER_ENTITY.CONTRACT ||
        entity === BRANCH_FILTER_ENTITY.APP) {
      const filterKey = getValue(filter.key)
      const appOptionValues = keyOptionValues?.[filterKey]

      filterComponent = <KeyOpValueVerticalFilter
        filter={filter}
        optionsKey={keyOptionsByEntityType?.[entity]}
        optionsValues={appOptionValues}
        onChange={(filter) => onChange({ newValue: filter })}
        disabled={disabled}
        fieldsAutoFocus={false}
        allowCustomValuesInMultiDropdown
      />
    } else if (entity === BRANCH_FILTER_ENTITY.USER) {
      filterComponent = <UserVerticalFilter
        criterion={filter as UserEntityCriterion}
        optionsKey={keyOptionsByEntityType?.[entity] as UserKeyOption[] | undefined}
        optionsValuesPerKey={keyOptionValues}
        onCriterionChange={(criterion) => onChange({ newValue: criterion })}
        disabled={disabled}
      />
    } else if (entity?.startsWith(BRANCH_FILTER_DYNAMIC_ENTITY_PREFIX)) {
      const idField = getIdFieldFromDynamicIdentity(entity)
      const { options } = filterDynamicOptionsDataByIdField?.[idField] ?? { options: [] }

      filterComponent = <KeyOpValueVerticalFilter
        filter={filter}
        optionsKey={options}
        onChange={(filter) => onChange({ newValue: filter })}
        disabled={disabled}
        fieldsAutoFocus={false}
      />
    }
  }

  return (
    <VerticalFilterWrapper
      disabled={disabled}
      onRemove={onRemove}
    >
      <>
        {shouldRenderEntitySelector && <Select
          options={entityOptions}
          value={entity}
          onChange={entity => handleEntityChange(entity as EntityOption)}
          clearable={false}
          searchable
          openOnFocus
          disabled={disabled}
        />}
        {filterComponent}
      </>
    </VerticalFilterWrapper>
  )
}

export default BranchFilter
