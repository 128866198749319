import { WIDGET_TYPES } from '@reducers/dashboards/types'
import metricConfig from '@components/dashboard/widgets/metricWidget/config'
import pieChartConfig from '@components/dashboard/widgets/pieChartWidget/config'
import barChartConfig from '@components/dashboard/widgets/barChartWidget/config'

export const WIDGET_TO_CONFIG = {
  [WIDGET_TYPES.METRIC_WIDGET]: metricConfig,
  [WIDGET_TYPES.PIE_CHART_WIDGET]: pieChartConfig,
  [WIDGET_TYPES.BAR_CHART_WIDGET]: barChartConfig
}
