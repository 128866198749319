import styled from 'styled-components'
import colors from '@shared/style/colors'

export const Time = styled.div`
  color: ${colors.text};
  font-size: 11px;
`

export const EmptyStateDescription = styled.div`
    width: 1084px;
    height: 40px;
`
