import React, { ReactElement, useState, useEffect } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import Table from '@components/table'
import { useDispatch, useSelector } from 'react-redux'
import { getScheduledReportsExtraData, getScheduledReportsResources, isLoadingScheduledReports } from '@selectors/scheduledReports'
import type { ScheduledReportsTableProps } from '@components/scheduledReportsTable/types'
import { Column, getColumns } from '@components/scheduledReportsTable/columns'
import { updateReport, deleteScheduledReport, getOrgScheduledReports } from '@actions/scheduledReports'
import Confirmation from '@components/confirmation'
import { ButtonType, EmptyState, Link, toast, ToastType } from '@toriihq/design-system'
import config from '@root/config'
import { toggleShareReportPopup } from '@store/actions'
import map from 'lodash/map'
import { getScheduledReportsTablesInfo } from '@selectors/tables'
import { EMPTY_OBJECT, TABLES } from '@root/constants'
import { getFilterOptionsValuesPerKey } from '@lenses/filters'
import NoResults from '@media/no-results.svg'
import { EmptyStateDescription } from './styles'

const emptyScheduledReport = {
  id: undefined,
  reportName: ''
}

const ScheduledReportsTable = (props: ScheduledReportsTableProps): ReactElement => {
  const { idOrg, tableKey } = props
  const { filtersOptions } = TABLES.scheduledReportsTable

  const dispatch = useDispatch()

  const [isOpenDeleteConfirmation, setIsOpenDeleteConfirmation] = useState(false)
  const [selectedScheduledReport, setSelectedScheduledReport] = useState(emptyScheduledReport)
  const [columns, setColumns] = useState<Column[]>([])

  const scheduledReports = useSelector(getScheduledReportsExtraData)
  const resources = useSelector(getScheduledReportsResources)
  const loading = useSelector(isLoadingScheduledReports)
  const { preDefinedColumnsMapping } = useSelector(getScheduledReportsTablesInfo)[tableKey]
  const tableInfo = useSelector(getScheduledReportsTablesInfo)[tableKey] || EMPTY_OBJECT

  useEffect(() => {
    idOrg && dispatch(getOrgScheduledReports({ idOrg }))
  }, [idOrg, dispatch])

  useDeepCompareEffect(() => {
    if (!tableInfo || tableInfo === EMPTY_OBJECT) {
      return
    }
    const { columnsConfiguration } = tableInfo
    idOrg && setColumns(getColumns({ usersMap: resources.users, handleEnableToggle, handleDownloadAction, handleEditAction, handleDeleteAction, columnsConfiguration }))
  }, [idOrg, tableInfo, resources.users])

  const searchFilterMethod = (row, search) => {
    return row.reportName && row.reportName.toLowerCase().includes(search && search.toLowerCase())
  }

  const toggleDeleteConfirmation = (): void => {
    setIsOpenDeleteConfirmation(!isOpenDeleteConfirmation)
  }

  const handleDownloadAction = async ({ idReport }) => {
    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/reports/${idReport}/download`
    const newWindow = window.open(url, '_blank')
    if (newWindow) {
      newWindow.opener = null
    }
  }

  const handleEditAction = async ({ scheduledReport }) => {
    setSelectedScheduledReport(scheduledReport)
    await dispatch(toggleShareReportPopup({
      isOpen: true,
      pageName: 'Scheduled Reports Table',
      isEditMode: true,
      report: scheduledReport
    }))
  }

  const handleDeleteScheduledReport = async () => {
    const idSelectedScheduledReport = selectedScheduledReport.id
    idSelectedScheduledReport && await dispatch(deleteScheduledReport({ idOrg, idReport: idSelectedScheduledReport }))
    toggleDeleteConfirmation()
  }

  const deleteScheduledReportConfirmation = <Confirmation
    isOpen={isOpenDeleteConfirmation}
    header={'Delete scheduled share'}
    confirmText='Delete'
    declineText='Cancel'
    decline={() => toggleDeleteConfirmation()}
    confirm={handleDeleteScheduledReport}
    close={() => toggleDeleteConfirmation()}
    mainButtonType={ButtonType.destructive}
    text={`You are about to delete the "${selectedScheduledReport?.reportName}" report from your scheduled reports. This report will no longer be sent.`}
    modalWidth={'518px'}
  />

  const handleDeleteAction = async ({ scheduledReport }) => {
    setSelectedScheduledReport(scheduledReport)
    toggleDeleteConfirmation()
  }

  const handleEnableToggle = async ({ scheduledReport, isEnabled }) => {
    const updatedScheduledReport = { ...scheduledReport, isEnabled }
    setSelectedScheduledReport(updatedScheduledReport)
    dispatch(updateReport({ idOrg, idReport: scheduledReport.id, isEnabled }))
    isEnabled && toast({
      message: 'Report scheduling enabled',
      type: ToastType.SUCCESS
    })
  }

  const columnsOptions = map(preDefinedColumnsMapping, (value, key) => {
    return { value: key, label: value }
  })

  const filterOptionsValuesPerKey = getFilterOptionsValuesPerKey(scheduledReports, { filtersOptions }, resources.users)

  const renderEmptyState = () => {
    return <EmptyState
      image={<img src={NoResults} alt='No scheduled reports' />}
      title='No scheduled reports yet'
      description={
        <EmptyStateDescription>
          Recurring reports you schedule will appear here, allowing you to monitor them, update schedules, or change recipients.<br />
          To set up a scheduled report, navigate to a supported table in Torii and select Export &gt; Send as report.
        </EmptyStateDescription>
      }
      link={<Link href='https://support.toriihq.com/hc/en-us/articles/28179790220699'>Learn more</Link>}
    />
  }

  return (
    <>
      {isOpenDeleteConfirmation && deleteScheduledReportConfirmation}
      <Table
        tableKey={tableKey}
        data={scheduledReports}
        columns={columns}
        forceShowNumberOfResults
        searchable
        searchFilterMethod={searchFilterMethod}
        configurableColumns
        configurableColumnsOptions={columnsOptions}
        loading={loading && scheduledReports.length === 0}
        filterable
        filtersOptions={filtersOptions}
        filterOptionsValuesPerKey={filterOptionsValuesPerKey}
        emptyStateMessage={renderEmptyState()}
      />
    </>
  )
}

export default ScheduledReportsTable
