import { ExpandableSection, Tooltip, TextBadge, Link } from '@toriihq/design-system'
import ToriiToggle from '@components/toriiToggle'
import React from 'react'
import * as Style from './style'
import { SUPPORT_ARTICLES } from '@root/articles'
import { analytics } from '@shared/services/workflows/analytics'

const FloodProtectionCollapsable: () => JSX.Element = () => {
  return <ExpandableSection
    key='floodProtection'
    title='Flood Protection'
    rightSlot={<TextBadge size='Small' color='blue'>On</TextBadge>}
  >
    <Style.ToggleRow>
      <span>Safeguard your workflows against unintended excessive executions.&nbsp;
        <Link
          href={SUPPORT_ARTICLES.WORKFLOWS_THROTTLED}
          onClick={() => analytics.throttledWorkflow.learnMore('Workflow settings')}
          target='_blank'
        >
          Learn more
        </Link>
      </span>
      <Tooltip
        label='Contact support to disable flood protection'
        placement='top'
      >
        <ToriiToggle
          disabled
          id='floodProtection'
          name='floodProtection'
          checked
          onToggle={() => {}}
        />
      </Tooltip>
    </Style.ToggleRow>
  </ExpandableSection>
}

export default FloodProtectionCollapsable
