import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import AdvancedFiltersRow from './advancedFiltersRow'
import { fontSize, fontWeight } from '@shared/style/sizes'
import { getValue } from '@shared/utils'
import { removeItemAtIndex, replaceItemAtIndex } from '@lenses/utils'
import { Button, ButtonType, ButtonSize, Spacer } from '@toriihq/design-system'
import { ADVANCED_FILTERS_DISPLAY_TYPE, displayTypeToComponent } from './consts'
import AndSeparator from '../_shared/andSeparator'
import EnableFor from '@components/enableFor'

const CSS = {
  main: css({
    borderRadius: '4px'
  }),
  addFilterIcon: css({
    fontSize: fontSize.extraSmall,
    fontWeight: fontWeight.light,
    marginRight: '5px',
    transform: 'translateY(-0.1px)'
  }),
  filtersBottomActions: css({
    display: 'flex',
    justifyContent: 'space-between'
  }),
  buttonsContainer: css({
    display: 'flex',
    gap: '8px'
  })
}

class AdvancedFilters extends React.Component {
  clearFilters = () => {
    const { onChange } = this.props
    onChange([])
  }

  onChange = (filter, index) => {
    const { onChange } = this.props
    const filters = replaceItemAtIndex(this.props.filters, filter, index)
    onChange && onChange(filters)
  }

  onAdd = () => {
    const { onChange } = this.props
    const filter = { id: Date.now() }
    const filters = this.props.filters.concat(filter)
    onChange && onChange(filters)
  }

  onRemove = (index) => {
    const { onChange } = this.props
    const filters = removeItemAtIndex(this.props.filters, index)
    onChange && onChange(filters)
  }

  render () {
    const { filters, optionsKey, optionsValuesPerKey, disabled, getTriggerPreview, supportClearAllFilters, overrideStyle, fieldsAutoFocus, displayType, allowCustomValuesInMultiDropdown } = this.props
    const Component = displayTypeToComponent[displayType]

    return (
      <div {...css(CSS.main, overrideStyle)}>
        {filters.map((filter, index) => {
          const shouldRenderAnd = index > 0 && displayType === ADVANCED_FILTERS_DISPLAY_TYPE.VERTICAL
          const optionsValues = optionsValuesPerKey[getValue(filter.key)]
          return Component && (
            <Fragment key={`filter-${index}`}>
              <AndSeparator shouldRenderAnd={shouldRenderAnd} />
              <Component
                key={`${filter.id}`}
                optionsKey={optionsKey}
                optionsValues={optionsValues}
                onChange={filter => this.onChange(filter, index)}
                onRemove={(e) => {
                  e.stopPropagation()
                  return this.onRemove(index)
                }}
                filter={filter}
                disabled={disabled}
                fieldsAutoFocus={fieldsAutoFocus}
                shouldRenderAnd={shouldRenderAnd}
                allowCustomValuesInMultiDropdown={allowCustomValuesInMultiDropdown}
              />
            </Fragment>
          )
        })}
        <div {...CSS.filtersBottomActions}>
          <div {...CSS.buttonsContainer}>
            <Button type={ButtonType.compact} size={ButtonSize.small} onClick={this.onAdd} disabled={disabled} icon='Plus' label='Add filter' />
            {getTriggerPreview && <Spacer left={'space-100'}>
              <EnableFor scopes={[]} allowForToriiAdmin>
                <Button type={ButtonType.secondary} onClick={getTriggerPreview} label='Preview' />
              </EnableFor>
            </Spacer> }
          </div>
          {supportClearAllFilters && <Button type={ButtonType.compact} size={ButtonSize.small} disabled={disabled || filters.length === 0} onClick={this.clearFilters} label='Clear filters' />}
        </div>
      </div>
    )
  }
}

AdvancedFilters.propTypes = {
  optionsKey: AdvancedFiltersRow.propTypes.optionsKey,
  optionsValuesPerKey: PropTypes.object,
  filters: PropTypes.arrayOf(AdvancedFiltersRow.propTypes.filter),
  onChange: PropTypes.func,
  displayType: PropTypes.oneOf([ADVANCED_FILTERS_DISPLAY_TYPE.HORIZONTAL, ADVANCED_FILTERS_DISPLAY_TYPE.VERTICAL]),
  overrideStyle: PropTypes.object,
  allowCustomValuesInMultiDropdown: PropTypes.bool
}

AdvancedFilters.defaultProps = {
  optionsKey: [],
  optionsValuesPerKey: {},
  filters: [],
  supportClearAllFilters: true,
  fieldsAutoFocus: true,
  displayType: ADVANCED_FILTERS_DISPLAY_TYPE.HORIZONTAL,
  allowCustomValuesInMultiDropdown: false
}

export default AdvancedFilters
