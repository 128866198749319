import View from './view'
import { connect } from 'react-redux'
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  SCOPES,
  TABLES
} from '@root/constants'
import { getUserPreferences } from '@selectors/ui'
import { withRouter } from 'react-router-dom'
import { getCurrentUsers, getCurrentUsersFieldValues, isParentUserFilter, toggleShareReportPopup } from '@actions/'
import { getUserTablesInfo } from '@selectors/tables'
import { getPastUserConfigurableColumnsOptions, getUserConfigurableColumnsOptions } from '@selectors/userDetails'
import { getCurrentOrg } from '@selectors/org'
import { getCurrentUsersES, getCurrentUsersFieldValues as getCurrentUsersFieldValuesSelector } from '@selectors/users'
import { ops } from '@lenses/filters'
import AccessControl from '@lenses/accessControl'
import { filterOutNotAccessableColumns } from '../columns'

export const getCustomSelectOptions = () => {
  return [
    {
      value: 'currentUsers',
      label: 'Current Users',
      filters: [
        { key: 'isDeletedInIdentitySources', op: ops.equals, value: false },
        { key: 'isExternal', op: ops.equals, value: false },
        { key: 'activeAppsCount', op: ops.gt, value: 0 },
        isParentUserFilter
      ]
    },
    {
      value: 'pastUsers',
      label: 'Past Users',
      filters: [
        { key: 'isExternal', op: ops.equals, value: false },
        { key: 'isDeletedInIdentitySources', op: ops.equals, value: true },
        isParentUserFilter
      ]
    },
    {
      value: 'allUsers',
      label: 'All users',
      filters: [
        { key: 'isExternal', op: ops.equals, value: false },
        isParentUserFilter
      ]
    }
  ]
}

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const { domain } = getCurrentOrg(state)
  const tableInfos = getUserTablesInfo(state)
  const tableInfo = tableInfos[TABLES.usersTable.key]
  const configurableColumnsOptions = getUserConfigurableColumnsOptions(state)
  const configurablePastColumnsOptions = getPastUserConfigurableColumnsOptions(state)
  const currentUsers = getCurrentUsersES(state)
  const userPreferences = getUserPreferences(state)
  const { defaultSort = EMPTY_ARRAY } = userPreferences[TABLES.usersTable.key] || EMPTY_OBJECT
  const fieldsValues = getCurrentUsersFieldValuesSelector(state)
  const customSelectOptions = getCustomSelectOptions()
  const hasLicenseCostAndChargebackAccess = AccessControl.isAccessibleWithState({ scopes: [SCOPES.LICENSE_AND_CHARGEBACK_READ], state })
  const columnsRestrictions = { hasLicenseCostAndChargebackAccess }

  return {
    idOrg,
    domain,
    tableInfo,
    defaultSort,
    configurableColumnsOptions: filterOutNotAccessableColumns({ columns: configurableColumnsOptions, columnsRestrictions }),
    configurablePastColumnsOptions: filterOutNotAccessableColumns({ columns: configurablePastColumnsOptions, columnsRestrictions }),
    users: currentUsers.users,
    loading: currentUsers.loading,
    loadingMore: currentUsers.loadingMore,
    fieldsValues,
    total: currentUsers.total,
    defaultCustomSelectOption: customSelectOptions[0],
    customSelectOptions,
    tableInfos,
    columnsRestrictions: { hasLicenseCostAndChargebackAccess }
  }
}

const mapDispatchToProps = {
  getCurrentUsers,
  getCurrentUsersFieldValues,
  toggleShareReportPopup
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
