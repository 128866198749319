import sift from 'sift'
import uniqBy from 'lodash/uniqBy'
import uniq from 'lodash/uniq'
import get from 'lodash/get'
import flattenDeep from 'lodash/flattenDeep'
import { getValue } from '../shared/utils'
import moment from 'moment'
import { types } from '../components/relativeDaySelect/view'
import { fieldTypes } from '../constants'
import { getDisplayName } from './users'
import { isArray } from 'lodash'

export const ops = {
  equals: 'equals',
  notEquals: 'notEquals',
  contains: 'contains',
  notContains: 'notContains',
  anyOf: 'anyOf',
  noneOf: 'noneOf',
  allOf: 'allOf',
  isExactly: 'isExactly',
  isSet: 'isSet',
  isNotSet: 'isNotSet',
  gt: 'gt',
  gte: 'gte',
  lt: 'lt',
  lte: 'lte',
  dayAfter: 'dayAfter',
  dayOnOrAfter: 'dayOnOrAfter',
  dayBefore: 'dayBefore',
  dayOnOrBefore: 'dayOnOrBefore',
  relativeDateMore: 'relativeDateMore',
  relativeDateOn: 'relativeDateOn',
  relativeDateLess: 'relativeDateLess',
  relativeDateToday: 'relativeDateToday',
  exists: 'exists',
  notExists: 'notExists',
  default: 'default'
}

export const opsWithValuesDropdown = [
  ops.equals,
  ops.notEquals,
  ops.anyOf,
  ops.noneOf,
  ops.allOf
]

export const opsWithNoValue = {
  [ops.isSet]: true,
  [ops.isNotSet]: true,
  [ops.relativeDateToday]: true
}

const getSignForRelativeDateFilter = (type) => type.value === types.ago ? -1 : 1
const getStartOfDayWithDayOffset = ({ offset = 0 } = {}) => moment.utc().startOf('day').add(offset, 'day').toDate().getTime()

const filterToSift = {
  [ops.equals]: ({ value }) => ({ $eq: value }),
  [ops.notEquals]: ({ value }) => ({ $ne: value }),
  [ops.contains]: ({ value }) => ({ $regex: `.*${value}.*`, $options: 'i' }),
  [ops.notContains]: ({ value }) => ({
    $or: [
      { $exists: false },
      { $regex: `^((?!${value}).)*$`, $options: 'i' }
    ]
  }),
  [ops.anyOf]: ({ value }) => ({ $in: value }),
  [ops.noneOf]: ({ value }) => ({ $nin: value }),
  [ops.allOf]: ({ value }) => ({ $all: value }),
  [ops.isExactly]: ({ value }) => ({ $and: [{ $size: value.length }, { $all: value }] }),
  [ops.isSet]: () => ({ $and: [{ $exists: true }, { $nin: [undefined, null] }] }),
  [ops.isNotSet]: () => ({ $or: [{ $exists: false }, { $in: [undefined, null] }] }),
  [ops.gt]: ({ value }) => ({ $gt: value }),
  [ops.gte]: ({ value }) => ({ $gte: value }),
  [ops.lt]: ({ value }) => ({ $lt: value }),
  [ops.lte]: ({ value }) => ({ $lte: value }),
  [ops.dayAfter]: ({ value }) => {
    const startOfTheNextDay = moment.utc(value).startOf('day').add(1, 'day').toDate().getTime()
    return { $gte: startOfTheNextDay }
  },
  [ops.dayOnOrAfter]: ({ value }) => {
    const startOfTheDay = moment.utc(value).startOf('day').toDate().getTime()
    return { $gte: startOfTheDay }
  },
  [ops.dayBefore]: ({ value }) => {
    const startOfTheDay = moment.utc(value).startOf('day').toDate().getTime()
    return { $lt: startOfTheDay }
  },
  [ops.dayOnOrBefore]: ({ value }) => {
    const startOfTheNextDay = moment.utc(value).startOf('day').add(1, 'day').toDate().getTime()
    return { $lt: startOfTheNextDay }
  },
  [ops.relativeDateToday]: () => {
    const start = getStartOfDayWithDayOffset()
    const end = moment.utc(start).add(1, 'day').toDate().getTime()
    return { $and: [{ $gte: start }, { $lt: end }] }
  },
  [ops.relativeDateOn]: ({ value }) => {
    if (!value.type) {
      return {}
    }
    const sign = getSignForRelativeDateFilter(value.type)
    const start = getStartOfDayWithDayOffset({ offset: sign * value.number })
    const end = moment.utc(start).add(1, 'day').toDate().getTime()
    return { $and: [{ $gte: start }, { $lt: end }] }
  },
  [ops.relativeDateLess]: ({ value }) => {
    if (!value.type) {
      return {}
    }
    const sign = getSignForRelativeDateFilter(value.type)
    const start = value.type.value === types.ago ? getStartOfDayWithDayOffset({ offset: 1 }) : getStartOfDayWithDayOffset()
    const end = getStartOfDayWithDayOffset({ offset: sign * (value.type.value === types.ago ? value.number : parseInt(value.number, 10) + 1) })
    const { startOperator, endOperator } = value.type.value === types.ago ? { startOperator: '$lt', endOperator: '$gte' } : { startOperator: '$gte', endOperator: '$lt' }
    return { $and: [{ [startOperator]: start }, { [endOperator]: end }] }
  },
  [ops.relativeDateMore]: ({ value }) => {
    if (!value.type) {
      return {}
    }
    const sign = getSignForRelativeDateFilter(value.type)
    const date = getStartOfDayWithDayOffset({ offset: sign * (value.type.value === types.ago ? value.number - 1 : value.number) })
    const operator = value.type.value === types.ago ? '$lt' : '$gte'
    return { [operator]: date }
  },
  [ops.default]: () => ({})
}

export const applyFilters = (data = [], filters = [], accessors = {}) => {
  if (filters.length === 0) {
    return data
  }

  const siftFilters = filters.map(({ key, op, value }) => {
    key = getValue(key)
    op = getValue(op)
    value = getValue(value)
    const siftQuery = filterToSift[op] || filterToSift[ops.default]
    const valueIsOptional = (siftQuery.length === 0)
    const hasValue = (value !== undefined && value !== null)
    return { [key]: (hasValue || valueIsOptional) ? siftQuery({ value }) : {} }
  })

  const filtersWithOperator = siftFilters.filter(f => Object.keys(f).some(k => Object.keys(f[k]).length))

  if (!filtersWithOperator.length) {
    return data
  }

  const getter = (item) => {
    const itemClone = { ...item }
    Object.keys(accessors).forEach(key => {
      const accessor = accessors[key]
      itemClone[key] = accessor(itemClone)
    })
    return itemClone
  }

  return sift({ $and: filtersWithOperator }, data, getter)
}

export const getUniqueValuesWithAccessor = (data, accessor) => {
  const items = data.map(accessor)
  const flattenItems = flattenDeep(items)
  const uniqItems = uniqBy(flattenItems, 'value')
  return uniqItems.filter(item => Boolean(item?.value))
}

export const getUniqueValues = (data, keyName) => {
  return uniq(flattenDeep(data.map(item => item[keyName] || get(item, keyName))))
    .filter(item => Boolean(item))
    .map(item => ({
      value: item,
      label: item
    }))
}

export const getFilterOptionsValuesPerKey = (data, tableInfo, usersById) => {
  const textOptionsValues = tableInfo.filtersOptions
    .filter(option => fieldTypes.text === option.type)
    .reduce((result, option) => {
      result[option.value] = getUniqueValues(data, option.value).sort((a, b) => a.label.toString().localeCompare(b.label.toString()))
      return result
    }, {})

  const dropdownOptionsValues = tableInfo.filtersOptions
    .filter(option => [fieldTypes.dropdown, fieldTypes.dropdownMulti].includes(option.type) && option.options)
    .reduce((result, option) => {
      result[option.value] = getUniqueValuesWithAccessor(data, (item) => {
        const values = [].concat(item[option.value])
        return values.map(value => {
          const label = (option.options.find(option => option.value === value) || {}).label
          return {
            label,
            value
          }
        })
      })
      return result
    }, {})

  const usersOptionsValues = tableInfo.filtersOptions
    .filter(option => fieldTypes.user === option.type)
    .reduce((result, option) => {
      result[option.value] = getUniqueValuesWithAccessor(data, (item) => {
        const idUser = get(item, [option.value, 'id']) || get(item, [option.value])
        const user = usersById[idUser] || {}

        return {
          label: getDisplayName({ ...user, emptyValue: '' }),
          value: idUser
        }
      })

      return result
    }, {})

  const multiUsersOptionsValues = tableInfo.filtersOptions
    .filter(option => fieldTypes.userMulti === option.type)
    .reduce((result, option) => {
      result[option.value] = getUniqueValuesWithAccessor(flattenDeep(data), (item) => {
        let value = get(item, [option.value])
        value = isArray(value) ? value : [value]
        return value?.filter(Boolean)?.map(idUser => {
          const user = usersById[idUser] || {}

          return {
            label: getDisplayName({ ...user, emptyValue: '' }),
            value: idUser
          }
        })
      })
      return result
    }, {})

  const contractOptionsValues = tableInfo.filtersOptions
    .filter(option => fieldTypes.contractsDropdownMulti === option.type && option.options)
    .reduce((result, option) => {
      result[option.value] = data.map(contract => ({ label: contract.name, value: contract.id }))
      return result
    }, {})

  return {
    ...usersOptionsValues,
    ...multiUsersOptionsValues,
    ...textOptionsValues,
    ...dropdownOptionsValues,
    ...contractOptionsValues,
    name: getUniqueValues(data, 'name')
  }
}
