import styled, { css } from 'styled-components'

interface LogoContainerProps {
  $isWhiteLabel?: boolean
}

interface NavSideBarProps {
  $isCollapsed: boolean
}

interface HamburgerProps {
  $isOpen: boolean
}

interface LogoWrapperProps {
  $isScrolling?: boolean
  $isClickable?: boolean
}

export const NavSideBar = styled.div<NavSideBarProps>`
  min-width: ${props => props.$isCollapsed ? '80px' : '228px'};
  max-width: ${props => props.$isCollapsed ? '80px' : '228px'};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.palette.background.primaryActive};
`

export const Body = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  overflow-y: auto;
  overflow-x: hidden;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const Hamburger = styled.div<HamburgerProps>`
  display: flex;
  cursor: pointer;
  ${props => props.$isOpen ? css`
    margin-right: 8px;
  ` : css`
    justify-content: center;
    width: 100%;
  `}
`

export const LogoWrapper = styled.div<LogoWrapperProps>`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  box-sizing: content-box;
  padding: 24px 16px 12px;
  cursor: default;
  
  ${props => props.$isClickable && css`
    cursor: pointer;
    
    &:focus {
      border-color: ${props.theme.palette.border.active};
    }
  `}

  ${props => props.$isScrolling && css`
    border-bottom: 1px solid ${props.theme.palette.border.primary};
  `}
`

export const LogoContainer = styled.div<LogoContainerProps>`
  margin-left: 8px;
  ${props => props.$isWhiteLabel && `
    background: ${props.theme.palette.background.primary};
  `}
`

export const WhiteLabel = styled.div`
  background: ${({ theme }) => theme.palette.background.primaryActive};
`

export const Image = styled.img`
  max-width: 130px;
  height: auto;
  max-height: 35px;
  width: auto;
`
