import { useSelector } from 'react-redux'
import { Props, TriggerConfigurationUtils, TriggerSteps, TriggerStep } from './types'
import { getAppCatalogPoliciesResourcesSelector, getTriggersByType } from '@selectors/workflows'
import { getTriggerValidationObj, isTriggerStepValid } from './utils'
import { BRANCHING_TYPE, TriggersByType, TriggerConfiguration } from '@selectors/workflows/types'
import { WORKFLOW_TRIGGER_TYPE } from '@root/shared/types'
import { getFinalLabelUsingDynamicLabelField } from '@lenses/workflows'

export const useTriggerConfiguration = ({ workflow }: Props): TriggerConfigurationUtils => {
  const triggersByType: TriggersByType = useSelector(getTriggersByType)
  const workflowTriggerType = workflow.triggerType
  const trigger: TriggerConfiguration | null = workflowTriggerType && triggersByType[workflowTriggerType]
  const uiConfig = trigger?.uiConfig

  const { appsMap } = useSelector(getAppCatalogPoliciesResourcesSelector)

  const getTriggerSteps = (): TriggerSteps => {
    const triggerConfigurationSteps: TriggerSteps = []

    const triggerUiConfigSteps = uiConfig?.steps || []
    const supportSchedule = trigger?.supportSchedule ?? false

    triggerUiConfigSteps.forEach((step, currentStepIndex) => {
      const isTriggerValid = isTriggerStepValid({ workflow, step, currentStepIndex, supportSchedule })
      const isValidData = getTriggerValidationObj({ isTriggerValid })

      const configurationStep: TriggerStep = {
        type: step.type,
        fields: step.fields,
        ...isValidData
      }
      triggerConfigurationSteps.push(configurationStep)
    })

    return triggerConfigurationSteps
  }

  const getRequestAccessTriggerLabel = (): string => {
    const policyIdApp = workflow.triggerIdApp
    const policyApp = policyIdApp ? appsMap[policyIdApp] : undefined
    const triggerAppName = policyApp?.name

    return triggerAppName ? `Access request for ${triggerAppName}` : `Access request for any catalog app`
  }

  const getTriggerLabel = (): string | undefined => {
    if (trigger?.type === WORKFLOW_TRIGGER_TYPE.REQUEST_ACCESS) {
      return getRequestAccessTriggerLabel()
    }

    return getFinalLabelUsingDynamicLabelField(uiConfig ?? {}, workflow.triggerConfiguration)
  }

  const getIsTriggerValid = () => {
    const isTriggerSelected = Boolean(workflowTriggerType && workflow.idApp)
    const triggerConfigurationSteps = getTriggerSteps()
    const hasInvalidStep = triggerConfigurationSteps.some(step => !step.isValid)
    return !isTriggerSelected || !hasInvalidStep
  }

  const getTriggerDescription = () => {
    return uiConfig?.description
  }

  const getTriggerErrorMsg = () => {
    const isTriggerValid = getIsTriggerValid()
    if (isTriggerValid) {
      return
    }

    const triggerConfigurationSteps = getTriggerSteps()
    const triggerStepsErrors = triggerConfigurationSteps.filter(step => !step.isValid)
    return triggerStepsErrors.map(step => step.errorMsg).join(', ')
  }

  const getIsTriggerSupportBranching = () => {
    const branchingTypes: BRANCHING_TYPE[] = [
      BRANCHING_TYPE.FIELD_TYPE,
      BRANCHING_TYPE.DYNAMIC
    ]
    return Boolean(trigger?.outputSchema &&
      Object.values(trigger.outputSchema).some(field =>
        field.branchingType && branchingTypes.includes(field.branchingType)
      )
    )
  }

  return {
    triggerSteps: getTriggerSteps(),
    triggerLabel: getTriggerLabel(),
    badgeLabel: uiConfig?.badge,
    badgeColor: uiConfig?.badgeColor,
    isTriggerValid: getIsTriggerValid(),
    triggerDescription: getTriggerDescription(),
    triggerErrorMsg: getTriggerErrorMsg(),
    isTriggerSupportBranching: getIsTriggerSupportBranching()
  }
}
