import moment from 'moment'
import { RenewalAlertState } from './RenewalAlertState'

export const getRenewalAlertState = ({ renewalDate, cancellationDeadline, isAutoRenewal, daysRemainToRenewal }:{renewalDate:Date, cancellationDeadline: Date, isAutoRenewal:boolean, daysRemainToRenewal:number}) => {
  const isRenewalInMoreThan120Days = daysRemainToRenewal > 120
  if (moment().isAfter(renewalDate)) {
    return RenewalAlertState.RENEWAL_DATE_PASSED
  }

  if (!isAutoRenewal) {
    return isRenewalInMoreThan120Days ? RenewalAlertState.MORE_THAN_120_DAYS_DISABLED_AUTO_RENEWAL : RenewalAlertState.LESS_THAN_120_DAYS_DISABLED_AUTO_RENEWAL
  }

  if (!cancellationDeadline) {
    return isRenewalInMoreThan120Days ? RenewalAlertState.MORE_THAN_120_DAYS_NO_CANCELLATION_DEADLINE : RenewalAlertState.LESS_THAN_120_DAYS_NO_CANCELLATION_DEADLINE
  }

  if (moment().isAfter(cancellationDeadline)) {
    return RenewalAlertState.CANCELLATION_DEADLINE_PASSED
  }

  return isRenewalInMoreThan120Days ? RenewalAlertState.MORE_THAN_120_DAYS_RENEWAL : RenewalAlertState.LESS_THAN_120_DAYS_RENEWAL
}
