import { formFieldTypes } from '@root/constants'
import { ops } from '@root/lenses/filters'
import { DROPDOWN_OPTIONS_TYPE, WidgetConfig } from '../types'
import { widgetInfoConfig } from '@components/dashboard/widgets/shared'

const config: WidgetConfig = {
  editableSections: [
    widgetInfoConfig,
    {
      key: 'dataConfig',
      label: 'Data',
      fields: [
        {
          key: 'metricFunc',
          label: 'Aggregation',
          type: formFieldTypes.dropdown,
          options: [
            { label: 'Count', value: 'total' },
            { label: 'Summation', value: 'sum' },
            { label: 'Average', value: 'avg' },
            { label: 'Maximum', value: 'max' },
            { label: 'Minimum', value: 'min' }
          ]
        },
        {
          key: 'metricField',
          label: 'Field',
          type: formFieldTypes.dropdown,
          dropdownOptionsType: DROPDOWN_OPTIONS_TYPE.NUMERIC,
          showOnFieldSpecificCondition: { fieldKey: 'metricFunc', comparisonType: 'value', value: 'total', op: ops.notEquals }
        },
        {
          key: 'filters',
          label: 'Filter by',
          type: formFieldTypes.filters
        }
      ]
    }
  ]
}

export default config
