import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Icon, ScreenBreakpoints } from '@toriihq/design-system'
import Analytics from '@helpers/analytics'
import TrialCountdown from '@components/trialCountdown'
import NavigationMenu from '@components/navigation/NavigationMenu'
import PropTypes from 'prop-types'
import { useMedia, useCombinedRefs } from '@shared/hooks'
import {
  NavSideBar,
  Body,
  Footer,
  Hamburger,
  LogoWrapper,
  LogoContainer,
  Image
} from './styles.ts'
import LogoWithTextColor from '@media/logo_with_text_color.svg'
import LogoColor from '@media/logo_color.svg'

const NavigationWithoutOverlay = React.forwardRef((props, ref) => {
  const { isWhiteLabel, companyName, email, whiteLabelLogoUrl, isTrial, isOnlyAppOwner, forceNavigationCollapse } = props
  const isScreenAboveMedium = useMedia(`(min-width: ${ScreenBreakpoints.md})`)
  const getInitialIsOpen = () => isScreenAboveMedium && !isOnlyAppOwner && !forceNavigationCollapse

  const [isHoverLogo, setIsHoverLogo] = useState(false)
  const [isOpen, setIsOpen] = useState(getInitialIsOpen())

  const [isFirstTime, setIsFirstTime] = useState(true)
  const [isScrolling, setIsScrolling] = useState(false)

  const innerRef = useRef(ref)
  const combinedRef = useCombinedRefs(ref, innerRef)
  const bodyRef = useRef()

  const runAnimation = useCallback((keyframes) => {
    if (combinedRef.current?.animate) {
      combinedRef.current.animate(keyframes, { duration: 150, iterations: 1 })
    }
  }, [combinedRef])

  useLayoutEffect(() => {
    if (isFirstTime) {
      setIsFirstTime(false)
      return
    }
    if (isOpen) {
      runAnimation([{ minWidth: '80px', maxWidth: '80px' }, { minWidth: '228px', maxWidth: '228px' }])
    } else {
      runAnimation([{ minWidth: '228px', maxWidth: '228px' }, { minWidth: '80px', maxWidth: '80px' }])
    }
  }, [isFirstTime, isOpen, ref, runAnimation])

  useEffect(() => {
    setIsOpen(isScreenAboveMedium && !isOnlyAppOwner && !forceNavigationCollapse)
  }, [isScreenAboveMedium, forceNavigationCollapse, isOnlyAppOwner])

  const getLogo = () => {
    if (isOpen) {
      return isWhiteLabel ? whiteLabelLogoUrl : LogoWithTextColor
    } else {
      return isWhiteLabel ? whiteLabelLogoUrl : LogoColor
    }
  }

  const onLogoClick = () => {
    if (isOnlyAppOwner) return

    setIsOpen(!isOpen)
    setIsHoverLogo(false)

    Analytics.track('Click on hamburger icon', {
      Action: isOpen ? 'Collapse' : 'Expend'
    })
  }

  const onLogoKeyPress = (event) => {
    if (event.key === 'Enter') {
      onLogoClick()
    }
  }

  const Logo = () => {
    const showLogo = Boolean(isOpen || (!isHoverLogo && !isWhiteLabel)) || isOnlyAppOwner
    const showHamburger = Boolean(isHoverLogo || (!isOpen && isWhiteLabel)) && !isOnlyAppOwner

    return (
      <LogoWrapper
        data-testid='menu-logo'
        onClick={onLogoClick}
        tabIndex={0}
        onKeyPress={onLogoKeyPress}
        onFocus={() => setIsHoverLogo(true)}
        onBlur={() => setIsHoverLogo(false)}
        as='a'
        $isScrolling={isScrolling}
        $isClickable={!isOnlyAppOwner}
      >
        {showLogo && (
          <LogoContainer data-testid='menu-logo-img' $isWhiteLabel={isWhiteLabel}>
            <Image
              alt={isWhiteLabel ? companyName : 'Torii Logo'}
              src={getLogo()}
            />
          </LogoContainer>
        )}
        {showHamburger && (
          <Hamburger data-testid='menu-logo-hamburger' $isOpen={isOpen}>
            <Icon name={isOpen ? 'Menu' : 'MenuCollapse'} />
          </Hamburger>
        )}
      </LogoWrapper>
    )
  }

  const onMenuScroll = () => {
    const scrollTop = bodyRef.current.scrollTop
    setIsScrolling(scrollTop > 0)
  }

  return (
    <NavSideBar
      data-testid='nav-sidebar'
      ref={combinedRef}
      data-email={email}
      onMouseOver={() => setIsHoverLogo(true)}
      onMouseLeave={() => setIsHoverLogo(false)}
      $isCollapsed={!isOpen}
    >
      <Logo />
      {!isOnlyAppOwner && (
        <Body
          data-testid='nav-menu'
          ref={bodyRef}
          onScroll={onMenuScroll}
        >
          <NavigationMenu isOpen={isOpen} />
          {isTrial && (
            <Footer>
              <TrialCountdown isOpen={isOpen} />
            </Footer>
          )}
        </Body>
      )}
    </NavSideBar>
  )
})

NavigationWithoutOverlay.propTypes = {
  isWhiteLabel: PropTypes.bool,
  companyName: PropTypes.string,
  email: PropTypes.string,
  whiteLabelLogoUrl: PropTypes.string,
  isTrial: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object)
}

export default NavigationWithoutOverlay
