import React, { useEffect } from 'react'
import { AdvancedCard, Stack } from '@toriihq/design-system'
import PieChart from '@components/pieChartBox/pieChart'
import pluralize from 'pluralize/pluralize'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentOrg } from '@selectors/org'
import Placeholder from '@components/placeholder'
import { getDashboardsData } from '@selectors/dashboards'
import capitalize from 'lodash/capitalize'
import { WidgetProps } from '../types'
import * as Style from './style'
import { getAggsForGroupBy, getFormattedData } from '@components/dashboard/widgets/utils'
import { getEntityDataForWidget } from '@actions/dashboards'
import { EMPTY_ARRAY, EMPTY_OBJECT, fieldTypes } from '@root/constants'
import PieChartWidgetTooltip from '@components/dashboard/widgets/pieChartWidget/PieChartWidgetTooltip'
import { useTheme } from 'styled-components'
import Currency from '@components/currency'
import numeral from 'numeral/numeral'
import { METRIC_FUNC } from '@reducers/dashboards/types'
import WidgetEmptyState from '../shared/emptyState'

const PieChartWidget = (props: WidgetProps) => {
  const dispatch = useDispatch()
  const { idDashboard, widget, onViewDataButtonClick, isSelected } = props

  const theme = useTheme()
  const { id: idOrg } = useSelector(getCurrentOrg)
  const dashboardsData = useSelector(getDashboardsData)
  const dashboardData = dashboardsData[idDashboard]
  const widgetsDataById = dashboardData?.widgetsData || EMPTY_OBJECT
  const widgetData = widgetsDataById[widget.id]

  const { loading, aggregations = EMPTY_ARRAY, displayConfig } = widgetData?.data ?? EMPTY_OBJECT

  const fieldType = displayConfig?.groupBy?.metric?.field?.type

  const categoricalColors = Object.entries(theme.palette.chart).filter(([key, value]) => key.startsWith('categorical')).map(([key, value]) => value)
  const data = getFormattedData({ displayConfig: displayConfig, aggregations }).map((entry, i) => ({ ...entry, color: categoricalColors[i] }))

  const valueDisplayFunction = (value) => {
    if (fieldType === fieldTypes.currency) {
      return <Currency value={value ?? 0} />
    }

    return numeral(value).format('0,0')
  }

  const loadingData = loading && data.length === 0

  useEffect(() => {
    if (idOrg) {
      dispatch(getEntityDataForWidget({
        entityType: widget.entityType,
        idOrg,
        idDashboard,
        displayConfig: widget.displayConfig,
        idWidget: widget.id,
        filters: widget.dataConfig.filters,
        aggs: getAggsForGroupBy({ widget })
      }))
    }
  }, [dispatch, idOrg, widget, idDashboard])

  const tooltipValueDisplayFunction = (value) => {
    if (widget?.displayConfig?.groupBy?.metric?.field?.type === fieldTypes.currency) {
      return <Stack gap={'space-050'}>
        <Currency value={value ?? 0} />
        <span>{capitalize(widget?.displayConfig?.groupBy?.metric?.field?.label)}</span>
      </Stack>
    }

    const text = widget?.displayConfig?.groupBy?.metric?.func === METRIC_FUNC.TOTAL ? widget?.entityType : widget?.displayConfig?.groupBy?.metric?.field?.label
    return `${numeral(value).format('0,0')} ${pluralize(capitalize(text), value)}`
  }

  return (<AdvancedCard
    title={widget?.displayConfig?.label}
    hoverable
    selected={isSelected}
    primaryButton={{
      icon: 'ViewTable',
      onClick: () => onViewDataButtonClick(widget),
      tooltipText: 'View data'
    }}
  >
    <Placeholder loading={loadingData} type='rect' style={Style.Placeholder}>
      {data.length ? <PieChart
        data={data}
        hasData
        loading={loadingData}
        header={''}
        customToolTip={<PieChartWidgetTooltip
          valueDisplayFunction={tooltipValueDisplayFunction}
          payload={undefined}
          active={undefined}
        />}
        valueDisplayFunction={valueDisplayFunction}
        overrideStyle={{ overflow: 'visible' }}
      />
        : <WidgetEmptyState />
      }
    </Placeholder>
  </AdvancedCard>)
}

export default PieChartWidget
