import { BRANCH_FILTER_ENTITY, BranchFilter, BranchFilterEntity } from '@shared/workflows/types'

export const changeBranchEntity = (
  branchFilter: BranchFilter,
  newEntity: BranchFilterEntity
): BranchFilter => {
  const newFilter = { id: Date.now() }
  const newUserFilter = { filters: newFilter }

  const filter =
    newEntity === BRANCH_FILTER_ENTITY.USER ? newUserFilter : newFilter

  const updatedBranchFilter = {
    ...branchFilter,
    entity: newEntity,
    filter,
    isValid: true,
    errorMsg: ''
  }
  return updatedBranchFilter as BranchFilter
}
