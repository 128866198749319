import styled from 'styled-components'

export const Header = styled.div<{ $shadow?: boolean }>`
  flex-shrink: 0;
  height: 26px;
  padding: 20px 12px 12px 20px;
  display: flex;
  cursor: pointer;
  align-items: center;
  box-sizing: content-box;
  ${({ $shadow, theme }) => $shadow && `border-bottom: 1px solid ${theme.palette.border.primary}`};
`

export const Logo = styled.div`
  margin-left: 16px;
`

export const NavPanelContainer = styled.div`
  position: relative;
  z-index: 3;
`

interface NavPanelProps {
  $windowWidth: number
}

export const NavPanel = styled.div<NavPanelProps>`
  width: ${props => `${props.$windowWidth * 0.8}px`};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.palette.background.primary};
`

interface PanelProps {
  $headerSpace?: number
  $canHideOverlay: boolean
}

export const Panel = styled.div<PanelProps>`
  position: fixed;
  width: 100%;
  background-color: rgba(54, 62, 103, 0.42);
  z-index: 10;
  top: ${props => props.$headerSpace ? `${props.$headerSpace}px` : 0};
  height: ${props => props.$headerSpace ? `calc(100% - ${props.$headerSpace}px)` : '100%'};
  display: ${props => props.$canHideOverlay ? 'none' : 'flex'};
`

export const Body = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  overflow-y: auto;
  overflow-x: hidden;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
`

export const WhiteLabel = styled.div`
  background: ${({ theme }) => theme.palette.background.primary};
`

export const Image = styled.img`
  max-width: 130px;
  height: auto;
  max-height: 35px;
  width: auto;
`
