export const base64Encode = (input: string): string => {
  const encoder = new TextEncoder()
  const data = encoder.encode(input)
  return window.btoa(String.fromCharCode(...data))
}

export const base64Decode = (base64: string): string => {
  const binaryString = window.atob(base64)
  const bytes = new Uint8Array([...binaryString].map(char => char.charCodeAt(0)))
  const decoder = new TextDecoder()
  return decoder.decode(bytes)
}
