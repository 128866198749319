import React, { Fragment, ReactElement, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AlertBox, AlertBoxType, Button, Icon, Spacer } from '@toriihq/design-system'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { WidgetContainer, WidgetResizer, DashboardGridContainer, ReminderWrapper } from './styles'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { Dashboard as DashboardType, Widget, WIDGET_TYPES } from '@reducers/dashboards/types'
import { updateDashboard } from '@actions/dashboards'
import { EMPTY_ARRAY, FEATURE_FLAGS } from '@root/constants'
import MetricWidget from '@components/dashboard/widgets/metricWidget'
import PieChartWidget from '@components/dashboard/widgets/pieChartWidget'
import {
  getIdOrg,
  getOrgPlanDetails,
  getSupportedFeatures as getSupportedFeaturesSelector
} from '@root/store/selectors/org'
import { getDashboardWidgetEditConfigDrawer } from '@selectors/ui'
import { IdOrg } from '@root/store/types'
import demoDashboard from '@root/store/actions/dashboardsFromServer'
import { WIDGET_TO_CONFIG } from '@components/dashboard/widgets/constants'
import { toggleDashboardWidgetEditConfigDrawer } from '@store/actions/dashboards'
import DashboardWidgetDataPopup from './dashboardWidgetDataPopup'
import BarChartWidget from '@components/dashboard/widgets/barChartWidget'
import { PLANS } from '@shared/features'
import { getAllPlans } from '@store/actions'
import Analytics from './analytics'
import useDeepCompareEffect from 'use-deep-compare-effect'

const ResponsiveGridLayout = WidthProvider(Responsive)

const Resizer = (resizeHandleAxis: string, ref) => {
  return (
    <WidgetResizer className={`react-resizable-handle react-resizable-handle-${resizeHandleAxis}`} ref={ref} $resizeHandleAxis={resizeHandleAxis}>
      <Icon name='ChevronDownRight' />
    </WidgetResizer>
  )
}

type DashboardProps = {
  dashboard: DashboardType
  isEditMode: boolean
  onDashboardUpdate: (dashboard) => void
}

export const BANNER_DESCRIPTION = 'Enjoy an early preview of this feature! Available for a limited time to all customers'

const Dashboard = (props: DashboardProps): ReactElement => {
  const dispatch = useDispatch()

  const idOrg: IdOrg = useSelector(getIdOrg)
  const { isOpen: isDrawerOpen } = useSelector(getDashboardWidgetEditConfigDrawer)
  const orgPlan = useSelector(getOrgPlanDetails)
  const showResetDashboardButton = useSelector(getSupportedFeaturesSelector)[FEATURE_FLAGS.SHOW_RESET_DASHBOARD_BUTTON]

  const { dashboard, isEditMode, onDashboardUpdate } = props
  const { id: idDashboard, title: dashboardName } = dashboard
  const widgets = dashboard.widgets || EMPTY_ARRAY
  const layoutConfig = dashboard.layoutConfig || EMPTY_ARRAY

  const [editedWidgets, setEditedWidgets] = useState(widgets)
  const [editedLayoutConfig, setEditedLayoutConfig] = useState(layoutConfig)
  const [allowAnimation, setAllowAnimation] = useState(false)
  const [idWidgetForViewDataPopup, setIdWidgetForViewDataPopup] = useState<number | null>(null)
  const [selectedIdWidget, setSelectedIdWidget] = useState<number | null>(null)
  const [isDataPopupOpen, setIsDataPopupOpen] = useState(false)
  const [userClosedReminder, setUserClosedReminder] = useState(false)

  const isEnterprise = orgPlan?.type.startsWith(PLANS.ENTERPRISE)
  const showReminder = (isEnterprise === false) && !isEditMode && !userClosedReminder

  useEffect(() => {
    const preventInitialAnimation = setTimeout(() => setAllowAnimation(true), 0)
    return () => clearTimeout(preventInitialAnimation)
  }, [])

  useEffect(() => {
    if (idOrg) {
      dispatch(getAllPlans())
    }
  }, [idOrg, dispatch])

  useEffect(() => {
    setEditedWidgets(widgets)
  }, [widgets])

  useDeepCompareEffect(() => {
    setEditedLayoutConfig(layoutConfig)
  }, [layoutConfig])

  useEffect(() => {
    if (!isDrawerOpen) {
      setSelectedIdWidget(null)
    }
  }, [isDrawerOpen])

  const layout = editedLayoutConfig.map((config) => {
    return {
      ...config,
      i: config.i.toString(),
      minW: 3,
      minH: 2
    }
  })

  const layoutMobile = layout.map((widget, i) => {
    return { ...widget, x: 0, w: 12, y: i, minW: 12, maxW: 12 }
  })

  const onWidgetUpdate = ({ updatedWidget }) => {
    const newWidgets = editedWidgets.map((widget) => widget.id === updatedWidget.id ? updatedWidget : widget)
    setEditedWidgets(newWidgets)
    dispatch(toggleDashboardWidgetEditConfigDrawer({ idDashboard, widget: updatedWidget }))
  }

  useEffect(() => {
    onDashboardUpdate({ ...dashboard, widgets: editedWidgets, layoutConfig: editedLayoutConfig })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedWidgets, editedLayoutConfig])

  const onViewDataButtonClick = (widget: Widget) => {
    Analytics.clickOnDrillDown({ dashboardName, widgetTitle: widget?.displayConfig.label })

    if (isDrawerOpen) {
      setSelectedIdWidget(widget.id)
      const config = WIDGET_TO_CONFIG[widget.type]
      dispatch(toggleDashboardWidgetEditConfigDrawer({ sections: config.editableSections, idDashboard, widget, onWidgetUpdate }))
    }

    setIdWidgetForViewDataPopup(widget.id)
    setIsDataPopupOpen(true)
  }

  const renderWidget = ({ widget }: { widget: Widget}): ReactElement | null => {
    const properties = { idDashboard, widget, onViewDataButtonClick, isSelected: (selectedIdWidget === widget.id) }

    switch (widget.type) {
      case WIDGET_TYPES.METRIC_WIDGET:
        return <MetricWidget {...properties} />

      case WIDGET_TYPES.PIE_CHART_WIDGET:
        return <PieChartWidget {...properties} />

      case WIDGET_TYPES.BAR_CHART_WIDGET:
        return <BarChartWidget {...properties} />

      default: {
        console.error('Unknown widget type', widget.type)
        return null
      }
    }
  }

  const onWidgetClick = ({ widget }) => {
    if (!isEditMode) {
      return
    }

    setSelectedIdWidget(widget.id)

    const config = WIDGET_TO_CONFIG[widget.type]
    dispatch(toggleDashboardWidgetEditConfigDrawer({ isOpen: true, sections: config.editableSections, idDashboard, widget, onWidgetUpdate }))
  }

  const onLayoutChange = (layout) => {
    if (!isEditMode) {
      return
    }

    const layoutConfig = layout.map(({ i, x, y, w, h }) => ({
      i: Number(i), x, y, w, h
    }))
    setEditedLayoutConfig(layoutConfig)
  }

  const onResizeStart = () => {
    setAllowAnimation(true)
  }

  const onResizeStop = (layout, oldItem, newItem) => {
    setAllowAnimation(false)

    if (oldItem.w !== newItem.w || oldItem.h !== newItem.h) {
      const widget = widgets.find(widget => Number(widget.id) === Number(oldItem.i))
      Analytics.resizeWidget({ dashboardName, widgetTitle: widget?.displayConfig.label })
    }
  }

  const onDragStart = () => {
    setAllowAnimation(true)
  }

  const onDragStop = (layout, oldItem, newItem) => {
    const widget = widgets.find(widget => Number(widget.id) === Number(oldItem.i))
    onWidgetClick({ widget })
    setAllowAnimation(false)

    if (oldItem.x !== newItem.x || oldItem.y !== newItem.y) {
      Analytics.dragWidget({ dashboardName, widgetTitle: widget?.displayConfig.label })
    }
  }

  const resetDashboard = () => {
    return dispatch(updateDashboard({ idOrg, idDashboard, dashboard: demoDashboard[0] }))
  }

  const className = ['layout', (allowAnimation && isEditMode ? 'animated' : null)].filter(Boolean).join(' ')

  const widgetForViewDataPopup: Widget | undefined = useMemo(() => {
    if (!idWidgetForViewDataPopup) {
      return undefined
    }
    return editedWidgets.find(widget => widget.id === idWidgetForViewDataPopup)
  }, [editedWidgets, idWidgetForViewDataPopup])

  return (
    <Fragment>
      { isDataPopupOpen && widgetForViewDataPopup
        ? <DashboardWidgetDataPopup isOpen isEditMode={isEditMode} widget={widgetForViewDataPopup} onWidgetUpdate={onWidgetUpdate} idDashboard={idDashboard} onCloseAction={() => setIsDataPopupOpen(false)} dashboardName={dashboardName} />
        : null
      }
      <DashboardGridContainer>
        {showReminder && <ReminderWrapper>
          <AlertBox type={AlertBoxType.INFORMATIVE}
            description={BANNER_DESCRIPTION}
            dismissible
            onDismiss={() => setUserClosedReminder(true)}
          />
        </ReminderWrapper>
        }
        <ResponsiveGridLayout
          isDraggable={isEditMode}
          isResizable={isEditMode}
          className={className}
          resizeHandle={Resizer}
          resizeHandles={['se', 'sw', 'nw', 'ne']}
          layouts={{ lg: layout, md: isEditMode ? layout : layoutMobile }}
          breakpoints={{ lg: 1000, md: 800 }}
          cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
          rowHeight={40}
          containerPadding={[0, 0]}
          margin={[24, 24]}
          draggableCancel={'[role=button]'}
          onLayoutChange={onLayoutChange}
          onDragStart={onDragStart}
          onDragStop={onDragStop}
          onResizeStart={onResizeStart}
          onResizeStop={onResizeStop}
        >
          {editedWidgets.map((widget) =>
            <WidgetContainer key={widget.id} isEditMode={isEditMode}>
              { renderWidget({ widget }) }
            </WidgetContainer>
          )}
        </ResponsiveGridLayout>
        <Spacer top='space-200' >
          {showResetDashboardButton && <Button icon={'MagicWand'} onClick={() => resetDashboard()} label='Reset dashboard' />}
        </Spacer>
      </DashboardGridContainer>
    </Fragment>
  )
}

export default Dashboard
