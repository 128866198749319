import React, { Fragment, useEffect, useState } from 'react'
import ToriiPopup from '../ToriiPopupV2'
import TriggerPreviewTable from '@components/popups/triggerPreviewPopup/triggerPreviewTable'
import { useDispatch, useSelector } from 'react-redux'
import { togglePendingExecutionsPopup, getTriggerPreview } from '@actions/'
import { getIdOrg } from '@selectors/org'
import { getTriggerPreview as getTriggerPreviewSelector, getTriggersByType, getWorkflow } from '@selectors/workflows'
import { EMPTY_OBJECT, SCOPES } from '@root/constants'
import { getTriggerPreviewPropsByTriggerType } from '@lenses/workflows'
import { toast, ToastType } from '@toriihq/design-system'
import { useHistory } from 'react-router-dom'
import { PREVIEW_TRIGGER_MODE } from '@root/constants.t'
import { unthrottleWorkflow } from '@actions/workflows'
import { useParamSelector } from '@shared/utils'
import { Content } from './styles'
import InputChecker from '@components/matchInput'

const header = 'Review pending executions'

const ReviewPendingExecutionsPopup = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { isOpen, idWorkflow } = useSelector(state => state.ui.pendingExecutionsPopup)
  const idOrg = useSelector(getIdOrg)
  const workflow = useParamSelector(getWorkflow, { idWorkflow })
  const workflowsTriggerPreview = useSelector(getTriggerPreviewSelector)
  const appsTriggersByType = useSelector(getTriggersByType)

  const { data: previewData, triggerType } = workflowsTriggerPreview[idWorkflow] || EMPTY_OBJECT
  const isSupportPreview = Boolean(appsTriggersByType?.[workflow.triggerType]?.supportPreviewForNewlyMatching)

  useEffect(() => {
    isSupportPreview && dispatch(getTriggerPreview({ idOrg, idWorkflow, previewMode: PREVIEW_TRIGGER_MODE.newMatchingEntities }))
  }, [dispatch, idOrg, idWorkflow, isSupportPreview])

  const onCancel = () => {
    dispatch(togglePendingExecutionsPopup({ isOpen: false, idWorkflow, workflowName: workflow.name }))
  }

  const displayUntrottleToast = () => {
    toast({
      message: 'Pending executions are being processed. Workflow has been unthrottled.',
      type: ToastType.SUCCESS,
      action: { label: 'View Executions', onClick: () => history.push(`/team/${idOrg}/workflow/${idWorkflow}/executions`) }
    })
  }

  const onUnthrottle = () => {
    dispatch(togglePendingExecutionsPopup({ isOpen: false, isByUser: false, idWorkflow, workflowName: workflow.name }))
    displayUntrottleToast()
    const unthrottleParams = isSupportPreview ? { idOrg, idWorkflow, entitiesAmount: previewData?.length } : { idOrg, idWorkflow }
    dispatch(unthrottleWorkflow(unthrottleParams))
  }

  const { popupWidth, getTheadThProps } = getTriggerPreviewPropsByTriggerType(triggerType)
  const popupSubBody = isSupportPreview ? 'Below is a list of paused records.' : ''

  const [inputCheckerValue, setInputCheckerValue] = useState('')
  const targetButtonText = 'Unthrottle'
  const targetText = targetButtonText.toLocaleUpperCase()
  const isMatch = inputCheckerValue === targetText

  return (
    <ToriiPopup isOpen={isOpen} onCloseAction={onCancel} width={popupWidth}>
      <ToriiPopup.Header header={header} />
      <ToriiPopup.Content>
        <Fragment>
          <Content>
            <div>
              <div>This workflow is currently throttled due to a high volume of triggers.</div>
              {popupSubBody && <div>{popupSubBody}</div>}
            </div>
            <InputChecker
              value={inputCheckerValue}
              setValue={(value) => setInputCheckerValue(value)}
              targetText={targetText}
              label={`To unthrottle the workflow, type ${targetText} and confirm`}
            />
            <TriggerPreviewTable idWorkflow={idWorkflow} getTheadThProps={getTheadThProps} />
          </Content>
        </Fragment>
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        isMainButtonDisabled={!isMatch || (isSupportPreview && !previewData?.length)}
        cancelButtonText='Cancel'
        mainButtonText={targetButtonText}
        mainButtonAction={onUnthrottle}
        scopes={[SCOPES.AUTOMATION_WRITE]}
      />
    </ToriiPopup>
  )
}

export default ReviewPendingExecutionsPopup
